<template>
  <div>
    <v-dialog
      v-model="dialogShowJobbers"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn icon dark @click="dialogShowJobbers = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Jobbers</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-2 bg-default">
          <v-row class="font-size-120 pt-1 pb-4">
            <v-col cols="12">
              <div class="card card-custom card-stretch gutter-b">
                <!-- <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Jobbers</h3>
                  </div>
                </div> -->
                <div class="card-body p-0">
                  <JobberList
                    :aJobbers="jobbers"
                    :modulo="'Jobber'"
                    :tipo="'dialog'"
                  ></JobberList>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import KTUtil from "@/assets/js/components/util";
import { GET_JOBBERS_FILTERED } from "@/core/services/store/jobber.module";
import JobberList from "./JobberList.vue";
export default {
  name: "JobberListDialog",
  components: {
    JobberList,
  },
  data() {
    return {
      dialogShowJobbers: false,
      jobbers: [],
    };
  },
  methods: {
    getJobbers(params) {
      KTUtil.setLoading();
      const oVue = this;
      oVue.$store.dispatch(GET_JOBBERS_FILTERED, params).then((tasks) => {
        oVue.jobbers = tasks;
        oVue.dialogShowJobbers = true;
        KTUtil.removeLoading();
      });
    },
  },
};
</script>
