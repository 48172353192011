<template>
  <div>
    <v-row>
      <v-col md="4">
        <v-select
          v-model="taskField"
          :items="fields"
          item-text="titulo"
          item-value="key"
          label="Seleccione Campo a Filtrar"
          dense
          v-on:change="taskFieldValues()"
        ></v-select>
      </v-col>
      <v-col md="4">
        <v-select
          v-model="fieldValue"
          :items="fieldValues"
          label="Seleccione Valor a Filtrar"
          dense
        ></v-select>
      </v-col>
      <v-col md="4">
        <v-btn color="primary" dark class="mb-2" v-on:click="showModal()">
          {{ selectionButtonText }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn
            icon
            dark
            @click="
              showDialog = false;
              filteredTasks = [];
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Tareas Sin asignar -
            <span> </span>{{ taskField !== "" ? fieldTitle(taskField) : "" }}:
            {{ fieldValue }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="12">
              <v-row class="mt-2">
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-select
                    v-model="functionalField"
                    :items="functionField.data"
                    :item-text="functionField.itemText"
                    :item-value="functionField.itemValue"
                    :label="functionField.label"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-btn color="primary" dark class="mb-2" @click="action()">
                    {{ functionField.buttonText }}
                  </v-btn>
                </v-col>
                <v-col cols="3"></v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-app>
                <v-data-table
                  :headers="headerTasks"
                  :items="filteredTasks"
                  disable-pagination
                  class="elevation-1"
                >
                  <template v-slot:[`header.asignar`]>
                    <v-switch
                      v-model="selectAll"
                      @change="selectAllTasks()"
                    ></v-switch>
                  </template>
                  <template v-slot:[`item.asignar`]="{ item }">
                    <v-checkbox v-model="item.checkAsignar"></v-checkbox>
                  </template>
                </v-data-table>
              </v-app>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { _ } from "vue-underscore";
export default {
  name: "TasksSelectorByFieldContent",
  props: {
    fields: Array,
    tasks: Array,
    headerTasks: Array,
    functionField: Object,
    selectionButtonText: String,
  },
  data() {
    return {
      showDialog: false,
      filteredTasks: [],
      selectAll: false,
      taskField: "",
      fieldValues: [],
      fieldValue: "",
      functionalField: "",
    };
  },
  methods: {
    showModal() {
      let oVue = this;
      if (oVue.taskField !== "" && oVue.fieldValue) {
        oVue.filteredTasks = oVue.tasks.filter((aJob) => {
          const bFieldFilter =
            aJob["campo_" + oVue.taskField].value === oVue.fieldValue;
          return aJob.asignada === false && bFieldFilter;
        });
        oVue.showDialog = true;
      }
    },
    taskFieldValues() {
      let oVue = this;
      const iFieldID = oVue.taskField;
      let aValues = [];
      _.map(_.sortBy(oVue.tasks, "campo_" + iFieldID), (aJob) => {
        aValues.push(aJob["campo_" + iFieldID].value);
      });

      aValues = _.map(
        _.groupBy(aValues, function (sVal) {
          return sVal;
        }),
        function (sVal) {
          return sVal[0];
        }
      );
      aValues = _.sortBy(aValues);
      oVue.fieldValues = aValues;
    },
    selectAllTasks() {
      let oVue = this;

      const bCheckValue = oVue.selectAll;

      for (let i in oVue.filteredTasks) {
        oVue.filteredTasks[i].checkAsignar = bCheckValue ? true : false;
      }
    },
    fieldTitle(sKey) {
      let oVue = this;
      if (sKey !== null) {
        const aField = _.findWhere(oVue.fields, {
          key: sKey,
        });
        if (aField !== undefined) {
          return aField.titulo;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    action() {
      const oVue = this;

      if (oVue.functionalField !== "") {
        const aTasks = oVue.filteredTasks.filter((aJob) => {
          return aJob.checkAsignar === true;
        });

        let aData = {
          selectedTasks: aTasks,
          field: oVue.functionalField,
        };

        oVue.$emit("action", aData);

        oVue.showDialog = false;
      }
    },
  },
};
</script>
