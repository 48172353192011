<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="searchDocument"
          append-icon="search"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headersDocuments"
          :items="documents"
          :search="searchDocument"
          class="table-striped elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on:click="dialogNewDocument = true"
              >
                Nuevo Documento
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showFile(item)">
              mdi-eye
            </v-icon>
            <v-icon small class="mr-2" @click="downloadFile(item)">
              mdi-download
            </v-icon>
            <v-icon small class="mr-2" v-on:click="deleteDocument(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogNewDocument"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">NUEVO DOCUMENTO</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="document.name"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="document.description"
                  label="Descripción"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <vue2Dropzone
                  id="dz"
                  ref="dz"
                  :options="document.dropzoneOption"
                ></vue2Dropzone>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="ml-2 mr-2"
            v-on:click="closeDocumentDialog"
            >Cerrar</v-btn
          >
          <v-btn
            color="primary"
            dark
            class="ml-2 mr-2"
            v-on:click="saveDocument"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="document.dialogShowDocument"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn icon dark @click="closeDocumentDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >VISTA PRELIMINAR DOCUMENTO {{ document.name }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <iframe
                  :src="document.urlPreliminar"
                  frameborder="0"
                  style="width: 100%; min-height: 500px"
                ></iframe>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import KTUtil from "@/assets/js/components/util";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Swal from "sweetalert2";
import {
  GET_DOCUMENTS,
  NEW_DOCUMENT,
  DELETE_DOCUMENT,
} from "@/core/services/store/campaign.module";
export default {
  name: "CampaignDocumentRepository",
  props: {
    campaignID: Number,
  },
  components: {
    vue2Dropzone,
  },
  data() {
    const oVue = this;

    return {
      headersDocuments: [
        {
          text: "Documento",
          value: "nombre",
        },
        {
          text: "Fecha de carga",
          value: "fecha",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      documents: [],
      searchDocument: "",
      dialogNewDocument: false,
      document: {
        name: "",
        description: "",
        document: "",
        urlPreliminar: "",
        dialogShowDocument: false,
        dropzoneOption: {
          url: "/",
          paramName: "file",
          maxFiles: 1,
          maxFilesize: 30,
          addRemoveLinks: true,
          acceptedFiles: ".doc,.docx,.xls,.xlsx,.csv,.pdf",
          autoProcessQueue: false,
          init: function () {
            this.on("addedfile", function (file) {
              let reader = new FileReader();
              reader.onload = function (event) {
                let base64String = event.target.result;
                oVue.document.document = base64String;
              };
              reader.readAsDataURL(file);
            });
            //eslint-disable-next-line no-unused-vars
            this.on("removedfile", function (file) {
              oVue.document.document = "";
            });
          },
          maxfilesexceeded: function (files) {
            this.removeAllFiles();
            this.addFile(files);
          },
        },
      },
    };
  },
  created() {
    const oVue = this;

    oVue.getDocuments();
  },
  methods: {
    async getDocuments() {
      KTUtil.setLoading();
      let oVue = this;
      const aDocumentsResponse = await oVue.$store.dispatch(
        GET_DOCUMENTS,
        oVue.campaignID
      );
      oVue.documents = aDocumentsResponse.data;
      KTUtil.removeLoading();
    },
    async dropDocument(iDocumentID, iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;

      const aData = {
        campaign: iCampaignID,
        document: iDocumentID,
      };
      const aDocumentResponse = await oVue.$store.dispatch(
        DELETE_DOCUMENT,
        aData
      );

      KTUtil.removeLoading();

      return aDocumentResponse;
    },
    async newDocument() {
      KTUtil.setLoading();
      let oVue = this;

      const iCampaignID = oVue.campaignID;
      const sName = oVue.document.name;
      const sDescription = oVue.document.description;
      const sBase64 = oVue.document.document;

      const aData = {
        campaign: iCampaignID,
        name: sName,
        description: sDescription,
        base64: sBase64,
      };
      const aDocumentResponse = await oVue.$store.dispatch(NEW_DOCUMENT, aData);

      KTUtil.removeLoading();

      return aDocumentResponse;
    },
    async saveDocument() {
      const oVue = this;

      const sName = oVue.document.name;
      const sDescription = oVue.document.description;
      const sBase64 = oVue.document.document;

      if (sName === "") {
        Swal.fire({
          title: "Error!",
          text: "Debe ingresar un nombre para el documento.",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
        return false;
      }
      if (sDescription === "") {
        Swal.fire({
          title: "Error!",
          text: "Debe ingresar una descripción para el documento.",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
        return false;
      }
      if (sBase64 === "") {
        Swal.fire({
          title: "Error!",
          text: "Debe ingresar un documento.",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
        return false;
      }

      const aNewDocumentResponse = await oVue.newDocument();
      if (aNewDocumentResponse.result) {
        Swal.fire({
          title: "¡Enhorabuena!",
          text: "El documento fue creado correctamente",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
        oVue.getDocuments();
      }

      oVue.closeDocumentDialog();
    },
    async deleteDocument(aDocument) {
      const oVue = this;

      const iDocumentID = aDocument.id;
      const iCampaignID = aDocument.id_campana;

      const aDeleteDocumentResponse = await oVue.dropDocument(
        iDocumentID,
        iCampaignID
      );
      if (aDeleteDocumentResponse.result) {
        Swal.fire({
          title: "¡Enhorabuena!",
          text: "El documento fue eliminado correctamente",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
        oVue.getDocuments();
      }
    },
    showFile(aDocument) {
      const oVue = this;

      oVue.document.name = aDocument.nombre;
      oVue.document.urlPreliminar =
        "https://docs.google.com/viewer?url=" +
        aDocument.url +
        "&embedded=true";

      oVue.document.dialogShowDocument = true;
    },
    downloadFile(aDocument) {
      window.open(aDocument.url, "_blank");
    },
    closeDocumentDialog() {
      const oVue = this;

      oVue.dialogNewDocument = false;
      oVue.document.dialogShowDocument = false;
      oVue.document.name = "";
      oVue.document.description = "";
      oVue.document.document = "";
      oVue.document.urlPreliminar = "";
      oVue.$refs.dz !== undefined && oVue.$refs.dz.removeAllFiles();
    },
  },
};
</script>
