<template>
  <div style="width: 100%; display: inline-flex">
    <v-col cols="6">
      <div class="card card-custom card-stretch">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Datos Generales</h3>
          </div>
        </div>
        <div class="card-body p-5 pb-7">
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">ID Campaña: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.campaignID }}
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Mandante: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.mandante }}
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Campaña: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.nombre }}
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Descripcion: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.descripcion }}
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Tipo de Campaña: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              <span>{{ campaignData.tipo }}</span>
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Estado: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              <span
                :class="
                  campaignData.estadoID == 1 ? 'text-danger' : 'text-success'
                "
              >
                {{ campaignData.estado }}
              </span>
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Fecha Inicio: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.fechaInicio }}
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Fecha Termino: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.fechaTermino }}
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Dias Campaña: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.diasTotales }}
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Dias Transcurridos: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.diasTranscurridos }}
              (
              <span
                :class="
                  campaignData.porcentajeDias < 100
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                {{ campaignData.porcentajeDias }}%
              </span>
              )
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Dias Restantes: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.diasTotales - campaignData.diasTranscurridos }}
              (
              <span
                :class="
                  100 - campaignData.porcentajeDias > 0
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                {{ 100 - campaignData.porcentajeDias }}%
              </span>
              )
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Tareas Campaña: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.cantidadTareas }}
            </v-col>
          </v-row>
          <v-row class="bb-light">
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Tareas Asignadas: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.cantidadTareasAsignadas }}
              (
              <span
                :class="
                  campaignData.porcentajeTareasAsignadas < 100
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                {{ campaignData.porcentajeTareasAsignadas }}%
              </span>
              )
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pt-2 pb-2">
              <span class="font-weight-bolder">Tareas Realizadas: </span>
            </v-col>
            <v-col cols="6" class="pt-2 pb-2">
              {{ campaignData.cantidadTareasRealizadas }}
              (
              <span
                :class="
                  campaignData.porcentajeTareasRealizadas < 100
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                {{ campaignData.porcentajeTareasRealizadas }}%
              </span>
              )(
              <span
                :class="
                  campaignData.porcentajeTareasRealizadas < 100
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                {{ campaignData.porcentajeTareas }}%
              </span>
              )
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
    <v-col cols="6">
      <div class="card card-custom card-stretch">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Mapa</h3>
          </div>
        </div>
        <div class="card-body p-5">
          <div>
            <TaskMap ref="task_map" :aJobs="jobs" :bCircle="true" />
          </div>
        </div>
      </div>
    </v-col>
  </div>
</template>
<script>
import TaskMap from "../task/TaskMap.vue";
export default {
  name: "CampaignDetail",
  components: {
    TaskMap,
  },
  props: {
    campaignData: Object,
    jobs: Array,
  },
  mounted() {
    let oVue = this;
    setTimeout(() => {
      oVue.$refs.task_map.initJobsMap();
    }, 500);
  },
};
</script>
