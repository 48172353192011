<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <div class="row">
          <div class="col-md-3" v-if="isAdmin">
            <v-autocomplete
              v-model="mandanteID"
              :items="mandantes"
              item-value="mandanteID"
              item-text="mandanteNombre"
              label="Mandante"
              dense
              @change="mandanteFilter"
            ></v-autocomplete>
          </div>
          <div class="col-md-3">
            <v-autocomplete
              v-model="estado"
              :items="estados"
              item-value="estadoID"
              item-text="estadoDescripcion"
              @change="filters"
              label="Estado"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-3">
            <v-autocomplete
              v-model="statusID"
              :items="status"
              item-value="statusID"
              item-text="statusNombre"
              label="Filtro"
              dense
              @change="filters"
            ></v-autocomplete>
          </div>
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headersCampaigns"
      :items="campaignsFiltered"
      :sort-by="['fechaCreacion']"
      :sort-desc="true"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Listado de Campañas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-on:click="showNewCampaignDialog()"
            v-if="isAdmin"
          >
            Nueva Campaña
          </v-btn>
          <v-dialog v-model="dialogDeleteCampaign" max-width="500px">
            <v-card>
              <v-card-title class="headline">
                ¿Esta seguro de eliminar la campaña?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="btn-danger"
                  class="mr-2"
                  @click="deleteItemConfirm"
                >
                  SI
                </v-btn>
                <v-btn @click="closeDelete"> NO </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip v-if="item.estadoID !== 1" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="showCampaign(item)"
              small
            >
              mdi-eye
            </v-icon>
          </template>
          <span>Ver</span>
        </v-tooltip>

        <v-tooltip v-if="item.estadoID == 1 && isAdmin" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="editCampaign(item)"
              small
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip v-if="item.estadoID !== 1" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              @click="updateEndDateDialog(item)"
            >
              mdi-calendar-edit
            </v-icon>
          </template>
          <span>Cambio fecha de termino</span>
        </v-tooltip>

        <v-tooltip v-if="item.estadoID == 1 && isAdmin" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              @click="deleteCampaign(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <span :class="item.estadoID == 1 ? 'text-danger' : 'text-success'">{{
          item.estado
        }}</span>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" :style="{ flex: 'none' }">
          <v-btn
            icon
            dark
            @click="
              getCampaigns();
              cleanScreen();
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nueva Campaña</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mt-2">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-3"
            id="campaignWizzard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Nav -->
            <div class="wizard-nav border-bottom mb-1 mb-lg-5">
              <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-label">
                    <h3 class="wizard-title"><span>1</span> Datos Generales</h3>
                    <div class="wizard-bar"></div>
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <h3 class="wizard-title">
                      <span>2</span> Configuración de Campos
                    </h3>
                    <div class="wizard-bar"></div>
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <h3 class="wizard-title">
                      <span>3</span> Ordenamiento de Campos
                    </h3>
                    <div class="wizard-bar"></div>
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <h3 class="wizard-title">
                      <span>4</span> Vista Preliminar
                    </h3>
                    <div class="wizard-bar"></div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Nav -->

            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10"
            >
              <div class="col-md-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <v-row>
                      <v-col cols="2" />
                      <v-col cols="8">
                        <v-row>
                          <v-col cols="4">
                            <v-text-field
                              v-model="wizardForm.generalData.campaignName"
                              label="Nombre de la Campaña"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="wizardForm.generalData.campaignType"
                              :items="campaignTypes"
                              item-text="descripcion"
                              item-value="codigo"
                              label="Tipo de Campaña"
                            ></v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="wizardForm.generalData.campaignWorkCost"
                              label="Costo por Tarea"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              rows="3"
                              v-model="
                                wizardForm.generalData.campaignDescription
                              "
                              label="Descripcion de Campaña"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col cols="6">
                                ¿Tiene Volante?
                                <v-app>
                                  <v-switch
                                    v-model="
                                      wizardForm.generalData.flyer.haveFlyer
                                    "
                                  ></v-switch>
                                </v-app>
                              </v-col>
                              <v-col
                                cols="6"
                                v-if="wizardForm.generalData.flyer.haveFlyer"
                              >
                                <v-text-field
                                  v-model="
                                    wizardForm.generalData.flyer
                                      .flyerShortCampaignName
                                  "
                                  label="Nombre corto de la Campaña"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="2" />
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12">
                            <v-subheader>Fecha Inicio / Termino</v-subheader>
                          </v-col>
                          <v-col cols="6">
                            <v-date-picker
                              v-model="wizardForm.generalData.campaignDates"
                              range
                              dark
                              full-width
                              selected-items-text=""
                            ></v-date-picker>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="dateRangeText"
                              label="Date range"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-app>
                              <v-data-table
                                :headers="
                                  wizardForm.generalData.headerSchedulesDates
                                "
                                :items="wizardForm.generalData.schedulesDates"
                                :item-class="rowSchedulesDatesClass"
                                disable-pagination
                                hide-default-footer
                                class="table-striped elevation-1"
                              >
                                <template v-slot:[`item.fecha`]="{ item }">
                                  {{ item.fecha }}
                                  <span class="capitalize">{{ item.dia }}</span>
                                </template>
                                <template v-slot:[`item.horaDesde`]="{ item }">
                                  <div v-if="item.irrenunciable === false">
                                    <div v-if="item.laborable">
                                      <b-form-timepicker
                                        v-model="item.horaDesde"
                                        locale="es-ES"
                                      ></b-form-timepicker>
                                    </div>
                                    <div v-else>
                                      {{ item.horaDesde }}
                                    </div>
                                  </div>
                                  <div v-else>
                                    {{ item.horaDesde }}
                                  </div>
                                </template>
                                <template v-slot:[`item.horaHasta`]="{ item }">
                                  <div v-if="item.irrenunciable === false">
                                    <div v-if="item.laborable">
                                      <b-form-timepicker
                                        v-model="item.horaHasta"
                                        locale="es-ES"
                                      ></b-form-timepicker>
                                    </div>
                                    <div v-else>
                                      {{ item.horaHasta }}
                                    </div>
                                  </div>
                                  <div v-else>
                                    {{ item.horaHasta }}
                                  </div>
                                </template>
                                <template v-slot:[`item.laborable`]="{ item }">
                                  <div v-if="item.irrenunciable === false">
                                    <v-switch
                                      v-model="item.laborable"
                                    ></v-switch>
                                  </div>
                                </template>
                              </v-data-table>
                            </v-app>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <v-row>
                      <v-col cols="12">
                        <div
                          class="text-center"
                          style="width: 50%; margin: 0 auto"
                        >
                          <h3 style="text-center">
                            Archivo de Datos de Tareas
                          </h3>
                          <b-form-group>
                            <b-form-file
                              accept=".xls, .xlsx"
                              placeholder="Seleccione un archivo"
                              @change="getExcelFile"
                              v-model="wizardForm.fields.file"
                              size="lg"
                            ></b-form-file>
                          </b-form-group>
                        </div>
                        <div v-if="wizardForm.fields.fileRows.length > 0">
                          <v-row>
                            <v-col
                              cols="12"
                              class="text-center font-weight-bolder"
                            >
                              <h3>
                                Cantidad de Tareas:
                                {{ wizardForm.fields.fileRows.length }}
                              </h3>
                            </v-col>
                            <v-col cols="12">
                              <b-tabs content-class="mt-3">
                                <b-tab title="Columnas" active>
                                  <v-row>
                                    <v-col md="6">
                                      <b-card-group deck>
                                        <b-card
                                          header="Columnas NO Seleccionadas"
                                          header-tag="header"
                                        >
                                          <div class="columnsContainer">
                                            <b-list-group>
                                              <b-list-group-item
                                                v-for="column in wizardForm
                                                  .fields.fileColumnsNoSelected"
                                                :key="column.key"
                                                v-on:click="
                                                  selectColumn(column)
                                                "
                                                class="cursor-pointer"
                                              >
                                                {{ column.name }}
                                              </b-list-group-item>
                                            </b-list-group>
                                          </div>
                                        </b-card>
                                      </b-card-group>
                                    </v-col>
                                    <v-col md="6">
                                      <b-card-group deck>
                                        <b-card
                                          header="Columnas Seleccionadas"
                                          header-tag="header"
                                        >
                                          <div class="columnsContainer">
                                            <b-list-group>
                                              <b-list-group-item
                                                v-for="column in wizardForm
                                                  .fields.fileColumnsSelected"
                                                :key="column.key"
                                                v-on:click="
                                                  unSelectColumn(column)
                                                "
                                                class="cursor-pointer"
                                              >
                                                {{ column.name }}
                                              </b-list-group-item>
                                            </b-list-group>
                                          </div>
                                        </b-card>
                                      </b-card-group>
                                    </v-col>
                                  </v-row>
                                </b-tab>
                                <b-tab title="Configuración">
                                  <v-row>
                                    <v-col cols="12">
                                      <v-row>
                                        <v-col md="6">
                                          <v-select
                                            v-model="wizardForm.fields.latitud"
                                            :items="
                                              wizardForm.fields.fileColumns
                                            "
                                            item-text="name"
                                            item-value="key"
                                            label="Seleccione Campo de Latitud"
                                            dense
                                          ></v-select>
                                        </v-col>
                                        <v-col md="6">
                                          <v-select
                                            v-model="wizardForm.fields.longitud"
                                            :items="
                                              wizardForm.fields.fileColumns
                                            "
                                            item-text="name"
                                            item-value="key"
                                            label="Seleccione Campo de Longitud"
                                            dense
                                          ></v-select>
                                        </v-col>
                                        <v-col md="6">
                                          <v-select
                                            v-model="wizardForm.fields.comuna"
                                            :items="
                                              wizardForm.fields.fileColumns
                                            "
                                            item-text="name"
                                            item-value="key"
                                            label="Seleccione Campo de Comuna"
                                            dense
                                          ></v-select>
                                        </v-col>
                                        <v-col md="6">
                                          <v-select
                                            v-model="
                                              wizardForm.fields.urlAntecedentes
                                            "
                                            :items="
                                              wizardForm.fields.fileColumns
                                            "
                                            item-text="name"
                                            item-value="key"
                                            label="Seleccione Campo de Url de Antecedentes"
                                            dense
                                          ></v-select>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-data-table
                                        :headers="
                                          wizardForm.fields.headersFileRows
                                        "
                                        :items="
                                          wizardForm.fields.fileRowsSample
                                        "
                                        class="elevation-1"
                                      >
                                        <template v-slot:top>
                                          <v-toolbar flat>
                                            <v-toolbar-title
                                              >Muestra de
                                              Tareas</v-toolbar-title
                                            >
                                            <v-divider
                                              class="mx-4"
                                              inset
                                              vertical
                                            ></v-divider>
                                            <v-spacer></v-spacer>
                                          </v-toolbar>
                                        </template>
                                        <template
                                          v-slot:[`item.map`]="{ item }"
                                        >
                                          <v-icon
                                            class="cursor-pointer"
                                            v-on:click="showMap(item)"
                                          >
                                            mdi-map-marker
                                          </v-icon>
                                        </template>
                                      </v-data-table>
                                    </v-col>
                                  </v-row>
                                </b-tab>
                                <b-tab title="Certificado de Tareas">
                                  <v-row>
                                    <v-col md="6">
                                      <v-app>
                                        Certificado Personalizado:
                                        <v-switch
                                          v-model="
                                            wizardForm.fields.customCertificate
                                              .haveCertificate
                                          "
                                        ></v-switch>
                                      </v-app>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="
                                      wizardForm.fields.customCertificate
                                        .haveCertificate
                                    "
                                  >
                                    <v-col md="6">
                                      <v-select
                                        v-model="
                                          wizardForm.fields.customCertificate
                                            .selectedCertificate
                                        "
                                        :items="
                                          wizardForm.fields.customCertificate
                                            .certifieds
                                        "
                                        item-text="nombreCertificado"
                                        item-value="uniqueID"
                                        label="Seleccione un certificado"
                                        dense
                                        v-on:change="selectCustomCertificate()"
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="
                                      wizardForm.fields.customCertificate
                                        .selectedCertificate !== null &&
                                      wizardForm.fields.customCertificate
                                        .haveCertificate
                                    "
                                  >
                                    <v-col md="6">
                                      <v-select
                                        v-model="
                                          wizardForm.fields.customCertificate
                                            .selectedConfig
                                        "
                                        :items="
                                          wizardForm.fields.customCertificate
                                            .configs
                                        "
                                        item-text="nombre"
                                        item-value="uniqueID"
                                        label="Seleccione una configuracion"
                                        dense
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </b-tab>
                                <b-tab
                                  title="Volante"
                                  v-if="wizardForm.generalData.flyer.haveFlyer"
                                >
                                  <v-row>
                                    <v-col md="6">
                                      <v-select
                                        v-model="
                                          wizardForm.fields.flyer.flyerRutField
                                        "
                                        :items="wizardForm.fields.fileColumns"
                                        item-text="name"
                                        item-value="key"
                                        label="Seleccione Campo de Rut"
                                        dense
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </b-tab>
                                <b-tab title="Código de barras">
                                  <v-row>
                                    <v-col md="6">
                                      <v-app>
                                        Código de barras:
                                        <v-switch
                                          v-model="
                                            wizardForm.fields.barCode
                                              .haveBarCode
                                          "
                                        ></v-switch>
                                      </v-app>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="wizardForm.fields.barCode.haveBarCode"
                                  >
                                    <v-col md="6">
                                      <v-select
                                        v-model="
                                          wizardForm.fields.barCode.field
                                        "
                                        :items="wizardForm.fields.fileColumns"
                                        item-text="name"
                                        item-value="key"
                                        label="Seleccione campo de codigo de barra"
                                        dense
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </b-tab>
                              </b-tabs>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-data-table
                          :headers="wizardForm.fields.headersFinalFields"
                          :items="wizardForm.fields.finalFields"
                          class="elevation-1"
                        >
                          <template v-slot:top>
                            <v-toolbar flat>
                              <v-toolbar-title
                                >Listado de Campos</v-toolbar-title
                              >
                              <v-divider
                                class="mx-4"
                                inset
                                vertical
                              ></v-divider>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-on:click="
                                  wizardForm.fields.dialogFields = true
                                "
                              >
                                Nuevo Campo
                              </v-btn>
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2 ml-2"
                                v-on:click="
                                  wizardForm.fields.dialogImportFields = true
                                "
                              >
                                Importar Campos
                              </v-btn>
                              <v-dialog
                                v-model="wizardForm.fields.dialogFields"
                                persistent
                                max-width="50%"
                              >
                                <v-card>
                                  <v-card-title>
                                    <span class="headline"
                                      >Configuración de campo</span
                                    >
                                  </v-card-title>
                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <v-col cols="12">
                                          <v-text-field
                                            v-model="
                                              wizardForm.fields.dialog.title
                                            "
                                            label="Titulo del Campo"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col
                                          v-if="
                                            wizardForm.fields.dialog.type !== 4
                                          "
                                          cols="12"
                                        >
                                          <v-app>
                                            Tiene Codigos:
                                            <v-switch
                                              v-model="
                                                wizardForm.fields.dialog
                                                  .haveCode
                                              "
                                            ></v-switch>
                                          </v-app>
                                        </v-col>
                                        <v-col cols="12">
                                          <v-row>
                                            <v-col cols="12">
                                              <v-select
                                                v-model="
                                                  wizardForm.fields.dialog.type
                                                "
                                                :items="
                                                  wizardForm.fields.dialog
                                                    .fieldsTypes
                                                "
                                                item-text="descripcion"
                                                item-value="id"
                                                label="Seleccione Tipo de Campo"
                                                dense
                                              ></v-select>
                                            </v-col>
                                            <v-col>
                                              <v-select
                                                v-if="
                                                  wizardForm.fields.dialog
                                                    .haveCode === true &&
                                                  wizardForm.fields.dialog
                                                    .type !== null &&
                                                  wizardForm.fields.dialog
                                                    .type !== 3 &&
                                                  wizardForm.fields.dialog
                                                    .type !== 4
                                                "
                                                v-model="
                                                  wizardForm.fields.dialog.code
                                                "
                                                :items="aCodigos"
                                                item-text="descripcion"
                                                item-value="id"
                                                label="Seleccione Codigo"
                                                dense
                                              ></v-select>
                                            </v-col>
                                            <v-col
                                              v-if="showRequiredFieldCheck"
                                              cols="12"
                                            >
                                              <v-app>
                                                Campo Obligatorrio:
                                                <v-switch
                                                  v-model="
                                                    wizardForm.fields.dialog
                                                      .required
                                                  "
                                                ></v-switch>
                                              </v-app>
                                            </v-col>
                                            <v-col
                                              cols="12"
                                              v-if="
                                                wizardForm.fields.dialog
                                                  .hasOptions
                                              "
                                            >
                                              <v-data-table
                                                :headers="
                                                  wizardForm.fields.dialog
                                                    .headersFieldOptions
                                                "
                                                :items="
                                                  wizardForm.fields.dialog
                                                    .fieldOptions
                                                "
                                                class="elevation-1"
                                              >
                                                <template v-slot:top>
                                                  <v-toolbar flat>
                                                    <v-toolbar-title
                                                      >Listado de
                                                      Opciones</v-toolbar-title
                                                    >
                                                    <v-divider
                                                      class="mx-4"
                                                      inset
                                                      vertical
                                                    ></v-divider>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                      color="primary"
                                                      dark
                                                      class="mb-2"
                                                      v-on:click="
                                                        wizardForm.fields.dialog.dialogOptions = true
                                                      "
                                                    >
                                                      Nueva Opción
                                                    </v-btn>
                                                    <v-dialog
                                                      v-model="
                                                        wizardForm.fields.dialog
                                                          .dialogOptions
                                                      "
                                                      persistent
                                                      max-width="50%"
                                                    >
                                                      <v-card>
                                                        <v-card-title>
                                                          <span class="headline"
                                                            >Configuración de
                                                            Opción</span
                                                          >
                                                        </v-card-title>
                                                        <v-card-text>
                                                          <v-container>
                                                            <v-row>
                                                              <v-col cols="12">
                                                                <v-text-field
                                                                  v-model="
                                                                    wizardForm
                                                                      .fields
                                                                      .dialog
                                                                      .option
                                                                      .text
                                                                  "
                                                                  label="Ingrese la Opción"
                                                                ></v-text-field>
                                                              </v-col>
                                                              <v-col>
                                                                <v-select
                                                                  v-if="
                                                                    wizardForm
                                                                      .fields
                                                                      .dialog
                                                                      .haveCode ===
                                                                      true &&
                                                                    wizardForm
                                                                      .fields
                                                                      .dialog
                                                                      .type ===
                                                                      3
                                                                  "
                                                                  v-model="
                                                                    wizardForm
                                                                      .fields
                                                                      .dialog
                                                                      .option
                                                                      .code
                                                                  "
                                                                  :items="
                                                                    aCodigos
                                                                  "
                                                                  item-text="descripcion"
                                                                  item-value="id"
                                                                  label="Seleccione Codigo"
                                                                  dense
                                                                ></v-select>
                                                              </v-col>
                                                            </v-row>
                                                          </v-container>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                          <v-spacer></v-spacer>
                                                          <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            v-on:click="
                                                              closeFieldOptionForm
                                                            "
                                                            >Cerrar</v-btn
                                                          >
                                                          <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            v-on:click="
                                                              saveOption
                                                            "
                                                            >Guardar</v-btn
                                                          >
                                                        </v-card-actions>
                                                      </v-card>
                                                    </v-dialog>
                                                  </v-toolbar>
                                                </template>
                                                <template
                                                  v-slot:[`item.actions`]="{
                                                    item,
                                                  }"
                                                >
                                                  <v-icon
                                                    small
                                                    class="mr-2"
                                                    v-on:click="
                                                      updateOption(item)
                                                    "
                                                  >
                                                    mdi-pencil
                                                  </v-icon>
                                                  <v-icon
                                                    small
                                                    v-on:click="
                                                      deleteOption(item)
                                                    "
                                                  >
                                                    mdi-delete
                                                  </v-icon>
                                                </template>
                                                <template
                                                  v-slot:[`item.code`]="{
                                                    item,
                                                  }"
                                                >
                                                  {{
                                                    codeDescription(item.code)
                                                  }}
                                                </template>
                                              </v-data-table>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      v-on:click="closeFieldForm"
                                      >Cerrar</v-btn
                                    >
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      v-on:click="saveField"
                                      >Guardar</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-dialog
                                v-model="wizardForm.fields.dialogImportFields"
                                persistent
                                max-width="50%"
                              >
                                <v-card>
                                  <v-card-title>
                                    <span class="headline"
                                      >Configuración masiva de campos</span
                                    >
                                  </v-card-title>
                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <v-col>
                                          <b-form-group>
                                            <b-form-file
                                              accept=".xls, .xlsx"
                                              ref="importExcelFile"
                                              placeholder="Seleccione un archivo"
                                              @change="getImportExcelFile"
                                              v-model="
                                                wizardForm.fields.importFile
                                              "
                                              size="lg"
                                            ></b-form-file>
                                          </b-form-group>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      v-on:click="
                                        wizardForm.fields.dialogImportFields = false
                                      "
                                      >Cerrar</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-toolbar>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                              small
                              class="mr-2"
                              v-on:click="updateField(item)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon small v-on:click="deleteField(item)">
                              mdi-delete
                            </v-icon>
                          </template>
                          <template v-slot:[`item.code`]="{ item }">
                            {{ codeDescription(item.code) }}
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <b-card-group deck>
                      <b-card
                        header="Ordenamiento De Columnas"
                        header-tag="header"
                      >
                        <v-row>
                          <v-col cols="12">
                            <b-list-group>
                              <draggable
                                v-model="wizardForm.fields.sortedFilesFields"
                              >
                                <transition-group>
                                  <div
                                    class="list-group-item cursor-pointer bg-secondary"
                                    v-for="(field, iFieldIndex) in wizardForm
                                      .fields.sortedFilesFields"
                                    :key="field.key"
                                  >
                                    <v-row>
                                      <v-col>
                                        <h3>
                                          {{ iFieldIndex + 1 }}.-
                                          {{ field.titulo }} - {{ field.tipo }}
                                        </h3>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </transition-group>
                              </draggable>
                              <draggable
                                v-model="wizardForm.fields.sortedConfigFields"
                                @end="sortConfigFieldsCallback"
                              >
                                <transition-group>
                                  <div
                                    class="list-group-item cursor-pointer bg-light"
                                    v-for="(field, iFieldIndex) in wizardForm
                                      .fields.sortedConfigFields"
                                    :key="field.key"
                                  >
                                    <v-row>
                                      <v-col>
                                        <h3>
                                          {{ iFieldIndex + 1 }}.-
                                          {{ field.titulo }} - {{ field.tipo }}
                                          <span v-if="field.tipoID !== 3">
                                            <span
                                              v-if="field.enlaceCampo !== ''"
                                            >
                                              - ({{
                                                fieldTitle(field.enlaceCampo)
                                              }})
                                            </span>
                                            <v-icon
                                              v-on:click="
                                                changeFieldLinkedField(
                                                  iFieldIndex
                                                )
                                              "
                                            >
                                              mdi-pencil
                                            </v-icon>
                                            <span
                                              v-if="
                                                field.enlaceCampo === '' &&
                                                iFieldIndex <
                                                  wizardForm.fields
                                                    .sortedConfigFields.length -
                                                    1
                                              "
                                            >
                                              <v-icon color="red">
                                                mdi-alert-box
                                              </v-icon>
                                            </span>
                                          </span>
                                        </h3>
                                      </v-col>
                                    </v-row>
                                    <v-row
                                      class="bg-white pl-5"
                                      v-if="fieldHasOptions(field.key)"
                                    >
                                      <v-col
                                        cols="12"
                                        class="border-bottom border-secondary"
                                        v-for="(
                                          option, iOptionIndex
                                        ) in field.opciones"
                                        :key="option.key"
                                      >
                                        {{ option.value }}
                                        <span
                                          v-if="
                                            field.tipoID === 3 &&
                                            iFieldIndex <
                                              wizardForm.fields
                                                .sortedConfigFields.length -
                                                1
                                          "
                                        >
                                          <span
                                            v-if="option.enlaceCampo !== ''"
                                          >
                                            -
                                            {{ fieldTitle(option.enlaceCampo) }}
                                          </span>
                                          <v-icon
                                            v-on:click="
                                              changeOptionLinkedField(
                                                iFieldIndex,
                                                iOptionIndex
                                              )
                                            "
                                          >
                                            mdi-pencil
                                          </v-icon>
                                          <span
                                            v-if="
                                              option.enlaceCampo === '' &&
                                              iFieldIndex <
                                                wizardForm.fields
                                                  .sortedConfigFields.length -
                                                  1
                                            "
                                          >
                                            <v-icon color="red">
                                              mdi-alert-box
                                            </v-icon>
                                          </span>
                                        </span>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </transition-group>
                              </draggable>
                            </b-list-group>
                            <v-dialog
                              v-model="
                                wizardForm.fields.dialog.option
                                  .dialogLinkedField
                              "
                              persistent
                              max-width="50%"
                            >
                              <v-card>
                                <v-card-title>
                                  <span class="headline"
                                    >Configuración de Opción</span
                                  >
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-select
                                          v-model="
                                            wizardForm.fields.dialog.option
                                              .linkedField
                                          "
                                          :items="
                                            wizardForm.fields
                                              .sortedConfigFieldsWithoutActualField
                                          "
                                          item-text="titulo"
                                          item-value="key"
                                          label="Seleccione el campo de enlace"
                                          dense
                                        ></v-select>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    v-on:click="
                                      wizardForm.fields.dialog.option.dialogLinkedField = false
                                    "
                                    >Cerrar</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    v-on:click="updateOptionLinkedField"
                                    >Guardar</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <div class="col text-right">
                                <div
                                  class="legend bg-secondary"
                                  :style="{
                                    width: '40px',
                                    height: '30px',
                                    display: 'inline-block',
                                  }"
                                ></div>
                                <div>TEREAS</div>
                              </div>
                              <div class="col">
                                <div
                                  class="legend bg-light"
                                  :style="{
                                    width: '40px',
                                    height: '30px',
                                    display: 'inline-block',
                                  }"
                                ></div>
                                <div>CONFIGURACIÓN</div>
                              </div>
                            </v-row>
                          </v-col>
                        </v-row>
                      </b-card>
                    </b-card-group>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <h3>{{ wizardForm.generalData.campaignName }}</h3>

                    <b-card class="mb-5">
                      <h5>Datos generales</h5>
                      <v-row>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Tipo de Campaña:</span
                          >
                          {{
                            campaignTypes.find(
                              (t) =>
                                t.codigo === wizardForm.generalData.campaignType
                            )?.descripcion
                          }}
                        </v-col>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Costo por tarea:</span
                          >
                          {{ wizardForm.generalData.campaignWorkCost }}
                        </v-col>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Fecha Inicio/Fin:</span
                          >
                          {{ dateRangeText }}
                        </v-col>
                      </v-row>
                    </b-card>

                    <b-card class="mb-5">
                      <h5>Datos Configuración (Campos)</h5>
                      <v-row>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Latitud:</span
                          >
                          {{
                            wizardForm.fields.fileColumns.find(
                              (f) => f.key === wizardForm.fields.latitud
                            )?.name
                          }}
                        </v-col>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Longitud:</span
                          >
                          {{
                            wizardForm.fields.fileColumns.find(
                              (f) => f.key === wizardForm.fields.longitud
                            )?.name
                          }}
                        </v-col>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Comuna Reposo:</span
                          >
                          {{
                            wizardForm.fields.fileColumns.find(
                              (f) => f.key === wizardForm.fields.comuna
                            )?.name
                          }}
                        </v-col>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Url de Antecedentes:</span
                          >
                          {{
                            wizardForm.fields.fileColumns.find(
                              (f) => f.key === wizardForm.fields.urlAntecedentes
                            )?.name
                          }}
                        </v-col>
                      </v-row>
                    </b-card>

                    <b-card
                      v-if="wizardForm.fields.barCode.haveBarCode"
                      class="mb-5"
                    >
                      <h5>Campos:</h5>
                      <v-row>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Campos Informativos:</span
                          >
                          {{ wizardForm.fields.sortedFilesFields.length }}
                        </v-col>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Campos Configurados:</span
                          >
                          {{ wizardForm.fields.sortedConfigFields.length }}
                        </v-col>
                      </v-row>
                    </b-card>

                    <b-card
                      v-if="wizardForm.fields.customCertificate.haveCertificate"
                      class="mb-5"
                    >
                      <h5>Certificado Personalizado</h5>
                      <v-row>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Certificado:</span
                          >
                          {{
                            wizardForm.fields.customCertificate.certifieds.find(
                              (c) =>
                                c.uniqueID ===
                                wizardForm.fields.customCertificate
                                  .selectedCertificate
                            )?.nombreCertificado
                          }}
                        </v-col>

                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }"
                            >Configuracion:</span
                          >
                          {{
                            wizardForm.fields.customCertificate.configs.find(
                              (c) =>
                                c.uniqueID ===
                                wizardForm.fields.customCertificate
                                  .selectedConfig
                            )?.nombre
                          }}
                        </v-col>
                      </v-row>
                    </b-card>

                    <b-card
                      v-if="wizardForm.fields.barCode.haveBarCode"
                      class="mb-5"
                    >
                      <h5>Codigo de barra</h5>
                      <v-row>
                        <v-col cols="4">
                          <span :style="{ 'font-weight': 'bold' }">Campo:</span>
                          {{
                            wizardForm.fields.fileColumns.find(
                              (c) => c.key === wizardForm.fields.barCode.field
                            )?.name
                          }}
                        </v-col>
                      </v-row>
                    </b-card>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Anterior
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="sendForm"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Guardar
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Siguiente
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
          <!--end: Wizard-->
        </v-card-text>
        <div :style="{ flex: '1 1 auto' }"></div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogShowCampaign"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" :style="{ flex: 'none' }">
          <v-btn
            icon
            dark
            @click="
              dialogShowCampaign = false;
              cleanScreen();
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Campaña: {{ wizardForm.generalData.campaignName }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-2 bg-default">
          <v-row class="font-size-120 pt-1 pb-4">
            <CampaignDetail
              :campaignData="campaign"
              :jobs="wizardForm.fields.fileRows"
            ></CampaignDetail>
            <v-col cols="12">
              <div class="card card-custom card-stretch gutter-b">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Campos y Tareas</h3>
                  </div>
                </div>
                <div class="card-body p-5 pb-7">
                  <b-tabs content-class="mt-3">
                    <b-tab title="CAMPOS" active>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            color="primary float-right"
                            dark
                            class="mb-2"
                            v-on:click="
                              downloadExcelFile(
                                'arbol',
                                'tree_table',
                                wizardForm.fields.headersTreeRows.length
                              )
                            "
                          >
                            Exportar
                          </v-btn>
                        </v-col>
                        <!-- <v-col cols=12>
                          <b-list-group>
                            <div class="list-group-item cursor-pointer bg-light" v-for="(field, iFieldIndex) in wizardForm.fields.sortedConfigFields" :key="field.key">
                              <v-row>
                                <v-col>
                                  <h3>
                                    {{ iFieldIndex + 1 }}.- {{ field.titulo }} - {{ field.tipo }}
                                    <span v-if="field.tipoID !== 3">
                                      <span v-if='field.enlaceCampo !== "" '> - ({{ fieldTitle(field.enlaceCampo) }}) </span>
                                    </span>
                                  </h3>
                                </v-col>
                              </v-row>
                              <v-row class="bg-white pl-5" v-if="fieldHasOptions(field.key)">
                                <v-col cols="12" class="border-bottom border-secondary font-size-120" v-for="option in field.opciones" :key="option.key" >
                                  {{ option.value }}
                                  <span v-if='option.enlaceCampo !== "" '> - {{ fieldTitle(option.enlaceCampo) }} </span>
                                </v-col>
                              </v-row>
                            </div>
                          </b-list-group>
                        </v-col> -->
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            :headers="wizardForm.fields.headersTreeRows"
                            :items="wizardForm.fields.treeRows"
                            disable-pagination
                            hide-default-footer
                            class="table-striped elevation-1"
                            id="tree_table"
                          >
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </b-tab>
                    <b-tab title="TAREAS">
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            :headers="wizardForm.fields.headersFileRows"
                            :items="wizardForm.fields.fileRows"
                            class="elevation-1"
                          >
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title>Listado</v-toolbar-title>
                                <v-divider
                                  class="mx-4"
                                  inset
                                  vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-on:click="
                                    downloadExcelFile(
                                      'campos',
                                      'jobs_table',
                                      wizardForm.fields.headersFileRows.length
                                    )
                                  "
                                >
                                  Exportar
                                </v-btn>
                              </v-toolbar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <div :style="{ display: 'inline-flex' }">
                                <TaskDetail :taskID="item.idJob"></TaskDetail>
                              </div>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-data-table
                          :headers="wizardForm.fields.headersFileRows"
                          :items="wizardForm.fields.fileRows"
                          class="elevation-1 d-none"
                          id="jobs_table"
                          disable-pagination
                          hide-default-footer
                        >
                        </v-data-table>
                      </v-row>
                    </b-tab>
                    <b-tab title="ASIGNACIÓN">
                      <v-row>
                        <v-col cols="12">
                          <b-tabs content-class="mt-3">
                            <b-tab title="SIN ASIGNAR" active>
                              <v-row>
                                <v-col cols="12">
                                  <TasksSelectorByFieldContent
                                    :functionField="{
                                      data: isAdmin
                                        ? jobbers // Se muestran todos los jobbers
                                        : jobbers.filter(
                                            (jobber) =>
                                              jobber.id_mandante == mandanteID
                                          ), // se muestran solo los jobbers asignados al mandante en cuestion
                                      itemText: 'nombre',
                                      itemValue: 'uniqueID',
                                      label: 'Seleccione un Jobber',
                                      buttonText: 'Asignar Jobber',
                                    }"
                                    selectionButtonText="TAREAS A ASIGNAR"
                                    :tasks="wizardForm.fields.fileRows"
                                    :headerTasks="
                                      wizardForm.fields.unassignmentJobsColumns
                                    "
                                    :fields="
                                      $_.filter(wizardForm.fields.finalFields, {
                                        origen: 'FILE',
                                      })
                                    "
                                    @action="assignJobsToJobber"
                                  />

                                  <TasksSelectorByFieldContent
                                    v-if="isAdmin"
                                    :functionField="{
                                      data: taskStates.filter((aState) => {
                                        return (
                                          aState.estadoID !== 2 &&
                                          aState.estadoID !== 3
                                        );
                                      }),
                                      itemText: 'estadoDescripcion',
                                      itemValue: 'estadoID',
                                      label: 'Seleccione un Estado',
                                      buttonText: 'Cambiar Estado',
                                    }"
                                    selectionButtonText="MODIFICAR ESTADO"
                                    :tasks="wizardForm.fields.fileRows"
                                    :headerTasks="
                                      wizardForm.fields.unassignmentJobsColumns
                                    "
                                    :fields="
                                      $_.filter(wizardForm.fields.finalFields, {
                                        origen: 'FILE',
                                      })
                                    "
                                    @action="changeMasiveJobState"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-data-table
                                    :headers="
                                      wizardForm.fields.unassignmentJobsColumns
                                    "
                                    :items="
                                      $_.filter(wizardForm.fields.fileRows, {
                                        asignada: false,
                                      })
                                    "
                                    :search="sSearchSinAsignar"
                                    class="elevation-1"
                                  >
                                    <template v-slot:top>
                                      <v-toolbar flat>
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                          v-model="sSearchSinAsignar"
                                          append-icon="search"
                                          label="Buscar"
                                          single-line
                                          hide-details
                                        ></v-text-field>
                                      </v-toolbar>
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                            </b-tab>
                            <b-tab title="ASIGNADAS">
                              <v-row>
                                <v-col cols="12">
                                  <v-data-table
                                    :headers="
                                      wizardForm.fields.assignedJobsColumns
                                    "
                                    :items="
                                      $_.filter(wizardForm.fields.fileRows, {
                                        asignada: true,
                                      })
                                    "
                                    :search="sSearchAsignadas"
                                    class="elevation-1 table-fixed"
                                  >
                                    <template v-slot:top>
                                      <v-toolbar flat>
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                          v-model="sSearchAsignadas"
                                          append-icon="search"
                                          label="Buscar"
                                          single-line
                                          hide-details
                                        ></v-text-field>
                                      </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.idJob`]="{ item }">
                                      <v-icon
                                        v-if="item.idEstado === 2"
                                        @click="unassignJob(item)"
                                      >
                                        mdi-delete
                                      </v-icon>
                                      {{ item.idJob }}
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                            </b-tab>
                            <b-tab title="JOBBERS">
                              <v-row>
                                <v-col cols="12">
                                  <table
                                    class="table table-bordered table-checkable dataTable no-footer dtr-inline collapsed"
                                    id="kt_datatable"
                                    role="grid"
                                    aria-describedby="kt_datatable_info"
                                  >
                                    <thead class="v-data-table-header">
                                      <tr role="row">
                                        <th aria-controls="kt_datatable">
                                          Nombre Jobber
                                        </th>
                                        <th aria-controls="kt_datatable">
                                          Tareas Asignadas
                                        </th>
                                        <th aria-controls="kt_datatable">
                                          Tareas Realizadas
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <template
                                        v-for="aJobber in $_.sortBy(
                                          $_.chain(wizardForm.fields.fileRows)
                                            .groupBy('nombreJobber')
                                            .map(function (value, index) {
                                              return {
                                                nombre: index,
                                                jobs: value,
                                              };
                                            })
                                            .value(),
                                          'nombre'
                                        )"
                                      >
                                        <tr
                                          v-if="aJobber.nombre != 'null'"
                                          :key="aJobber.key"
                                        >
                                          <td>{{ aJobber.nombre }}</td>
                                          <td>{{ aJobber.jobs.length }}</td>
                                          <td>
                                            {{
                                              $_.filter(aJobber.jobs, {
                                                idEstado: 3,
                                              }).length
                                            }}
                                          </td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </table>
                                </v-col>
                              </v-row>
                            </b-tab>
                          </b-tabs>
                        </v-col>
                      </v-row>
                    </b-tab>
                    <b-tab title="CALENDARIO">
                      <v-row>
                        <v-col class="text-right" cols="12">
                          <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="updateSchedule()"
                          >
                            Guardar
                          </v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-app>
                            <v-data-table
                              :headers="
                                wizardForm.generalData.headerSchedulesDates
                              "
                              :items="wizardForm.generalData.schedulesDates"
                              :item-class="rowSchedulesDatesClass"
                              disable-pagination
                              hide-default-footer
                              class="table-striped elevation-1"
                            >
                              <template v-slot:[`item.fecha`]="{ item }">
                                {{ item.fecha }}
                                <span class="capitalize">{{ item.dia }}</span>
                              </template>
                              <template v-slot:[`item.horaDesde`]="{ item }">
                                <div v-if="item.irrenunciable === false">
                                  <div v-if="item.laborable">
                                    <b-form-timepicker
                                      v-model="item.horaDesde"
                                      locale="es-ES"
                                    ></b-form-timepicker>
                                  </div>
                                  <div v-else>
                                    {{ item.horaDesde }}
                                  </div>
                                </div>
                                <div v-else>
                                  {{ item.horaDesde }}
                                </div>
                              </template>
                              <template v-slot:[`item.horaHasta`]="{ item }">
                                <div v-if="item.irrenunciable === false">
                                  <div v-if="item.laborable">
                                    <b-form-timepicker
                                      v-model="item.horaHasta"
                                      locale="es-ES"
                                    ></b-form-timepicker>
                                  </div>
                                  <div v-else>
                                    {{ item.horaHasta }}
                                  </div>
                                </div>
                                <div v-else>
                                  {{ item.horaHasta }}
                                </div>
                              </template>
                              <template v-slot:[`item.laborable`]="{ item }">
                                <div v-if="item.irrenunciable === false">
                                  <v-switch v-model="item.laborable"></v-switch>
                                </div>
                              </template>
                            </v-data-table>
                          </v-app>
                        </v-col>
                      </v-row>
                    </b-tab>
                    <b-tab title="MENSAJES">
                      <v-data-table
                        :headers="
                          wizardForm.dailyMessages.aHeaderCampaignDailyMessages
                        "
                        :items="wizardForm.dailyMessages.aCampaignDailyMessages"
                        :sort-desc="true"
                        :search="
                          wizardForm.dailyMessages.sSearchCampaignDailyMessages
                        "
                        class="elevation-1"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-toolbar-title
                              >Listado de Mensajes Diarios</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field
                              v-model="
                                wizardForm.dailyMessages
                                  .sSearchCampaignDailyMessages
                              "
                              append-icon="search"
                              label="Buscar"
                              single-line
                              hide-details
                            ></v-text-field>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              dark
                              class="mb-2"
                              v-on:click="openMassiveJobberMessagesModal()"
                            >
                              Nuevo Mensaje
                            </v-btn>
                          </v-toolbar>
                        </template>
                      </v-data-table>
                    </b-tab>
                    <b-tab
                      title="COBERTURA ZONAL"
                      v-if="
                        wizardForm.generalData.coberturaZonal.length > 0 &&
                        Number(currentUserPersonalInfo.perfil_id) === 1
                      "
                    >
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            :headers="
                              wizardForm.generalData.headersCoberturaZonal
                            "
                            :items="wizardForm.generalData.coberturaZonal"
                            disable-pagination
                            hide-default-footer
                            class="table-striped elevation-1"
                          >
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title>Listado</v-toolbar-title>
                                <v-divider
                                  class="mx-4"
                                  inset
                                  vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-on:click="
                                    downloadExcelFile(
                                      'cobertura_zonal',
                                      'cobertura_zonal_table',
                                      wizardForm.generalData
                                        .headersCoberturaZonal.length
                                    )
                                  "
                                >
                                  Exportar
                                </v-btn>
                              </v-toolbar>
                            </template>
                            <template v-slot:[`item.tareas`]="{ item }">
                              <span
                                class="cursor-pointer"
                                @click="getTasksFiltered(item.tareas)"
                              >
                                {{ item.tareas.length }}
                              </span>
                            </template>
                            <template v-slot:[`item.jobbers`]="{ item }">
                              <span
                                class="cursor-pointer"
                                @click="getJobbersFiltered(item.jobbers)"
                              >
                                {{ item.jobbers.length }}
                              </span>
                            </template>
                            <template v-slot:[`item.warning`]="{ item }">
                              <v-icon
                                class="text-warning"
                                v-if="item.jobbers === 0"
                              >
                                mdi-alert-circle
                              </v-icon>
                            </template>
                            <template slot="body.append">
                              <tr
                                class="font-weight-bolder"
                                v-if="
                                  Object.keys(
                                    wizardForm.generalData.coberturaZonal
                                  ).length > 0
                                "
                              >
                                <td>Total</td>
                                <td v-for="index in 2" :key="index"></td>
                                <td class="text-center">
                                  <span
                                    class="cursor-pointer"
                                    @click="
                                      getTasksFiltered(
                                        wizardForm.fields.fileRows
                                      )
                                    "
                                  >
                                    {{
                                      wizardForm.generalData.coberturaZonal.reduce(
                                        (s, a) => s + a.tareas.length,
                                        0
                                      )
                                    }}
                                  </span>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </template>
                          </v-data-table>
                          <v-data-table
                            :headers="
                              wizardForm.generalData.headersCoberturaZonalExcel
                            "
                            :items="wizardForm.generalData.coberturaZonalExcel"
                            disable-pagination
                            hide-default-footer
                            class="table-striped elevation-1 d-none"
                            id="cobertura_zonal_table"
                          >
                            <template v-slot:[`item.tareas`]="{ item }">
                              {{ item.tareas.length }}
                            </template>
                            <template v-slot:[`item.jobbers`]="{ item }">
                              {{ item.jobbers.length }}
                            </template>
                            <template slot="body.append">
                              <tr
                                class="font-weight-bolder"
                                v-if="
                                  Object.keys(
                                    wizardForm.generalData.coberturaZonal
                                  ).length > 0
                                "
                              >
                                <td>Total</td>
                                <td v-for="index in 2" :key="index"></td>
                                <td class="text-center">
                                  <span
                                    class="cursor-pointer"
                                    @click="
                                      getTasksFiltered(
                                        wizardForm.fields.fileRows
                                      )
                                    "
                                  >
                                    {{
                                      wizardForm.generalData.coberturaZonal.reduce(
                                        (s, a) => s + a.tareas.length,
                                        0
                                      )
                                    }}
                                  </span>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </b-tab>
                    <b-tab title="CERTIFICADO PERSONALIZADO">
                      <v-row>
                        <v-col md="6">
                          <v-select
                            v-model="
                              wizardForm.fields.customCertificate
                                .selectedCertificate
                            "
                            :items="
                              wizardForm.fields.customCertificate.certifieds
                            "
                            item-text="nombreCertificado"
                            item-value="uniqueID"
                            label="Seleccione un certificado"
                            dense
                            v-on:change="selectCustomCertificate()"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="
                          wizardForm.fields.customCertificate
                            .selectedCertificate !== null
                        "
                      >
                        <v-col md="6">
                          <v-select
                            v-model="
                              wizardForm.fields.customCertificate.selectedConfig
                            "
                            :items="wizardForm.fields.customCertificate.configs"
                            item-text="nombre"
                            item-value="uniqueID"
                            label="Seleccione una configuracion"
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="
                          wizardForm.fields.customCertificate.selectedConfig !==
                          null
                        "
                      >
                        <v-col md="6">
                          <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="sendCustomCertifiedData"
                          >
                            Asignar Certificado
                          </v-btn>
                        </v-col>
                      </v-row>
                    </b-tab>
                    <b-tab title="DOCUMENTOS" v-if="isAdmin">
                      <DocumentRepository
                        ref="DocumentRepository"
                        :campaignID="wizardForm.generalData.campaignID"
                      ></DocumentRepository>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="wizardForm.dailyMessages.dialogMassiveJobberMessage"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" :style="{ flex: 'none' }">
          <v-btn
            icon
            dark
            @click="
              wizardForm.dailyMessages.dialogMassiveJobberMessage = false;
              wizardForm.dailyMessages.campaignJobbersAssigned = [];
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Envio masivo de Mensaje </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="openGroupJobberMessageModal()"
              >
                Enviar mensaje
              </v-btn>
            </v-col>
          </v-row>
          <v-app>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headersMessageToSend"
                  :items="wizardForm.dailyMessages.campaignJobbersAssigned"
                  disable-pagination
                  class="elevation-1"
                >
                  <template v-slot:[`header.selected`]>
                    <v-switch
                      v-model="selectedAllMessages"
                      @change="changeStateAllMessages()"
                    ></v-switch>
                  </template>
                  <template v-slot:[`item.selected`]="{ item }">
                    <v-switch v-model="item.selected"></v-switch>
                  </template>
                  <template v-slot:[`item.tareasAsignadas`]="{ item }">
                    {{ item.jobs.length }}
                  </template>
                  <template v-slot:[`item.tareasRealizadas`]="{ item }">
                    {{ $_.filter(item.jobs, { idEstado: 3 }).length }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-app>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="wizardForm.dailyMessages.dialogJobberMessage"
      persistent
      max-width="50%"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Envio de Mensaje</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="datepicker"
                  v-model="wizardForm.dailyMessages.form.datepicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="wizardForm.dailyMessages.form.sFechaMensaje"
                      label="Fecha de Mensaje"
                      v-bind="attrs"
                      v-on="on"
                      class="m-0 p-0"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="
                      wizardForm.dailyMessages.form.sFechaMensajeDatepicker
                    "
                    @input="wizardForm.dailyMessages.form.datepicker = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="wizardForm.dailyMessages.form.groupJobberMessage"
                  label="Ingrese el mensaje"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            v-on:click="
              wizardForm.dailyMessages.form.groupJobberMessage = '';
              wizardForm.dailyMessages.dialogJobberMessage = false;
            "
            >Cerrar</v-btn
          >
          <v-btn color="blue darken-1" text v-on:click="sendGroupJobberMessage"
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateEndDateValues.dialog" persistent max-width="50%">
      <v-card>
        <v-card-title>
          <span class="headline">Cambio de fecha de termino</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-menu
                  ref="datepicker"
                  v-model="updateEndDateValues.datepicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="updateEndDateValues.date"
                      label="Fecha Gestión"
                      v-bind="attrs"
                      v-on="on"
                      class="m-0 p-0"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="updateEndDateValues.dateOriginal"
                    @input="datepicker = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            v-on:click="closeUpdateEndDateDialog"
            >Cerrar</v-btn
          >
          <v-btn color="blue darken-1" text v-on:click="updateEndDate"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TaskListDialog ref="TaskListDialog"></TaskListDialog>
    <JobberListDialog ref="JobberListDialog"></JobberListDialog>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
import { _ } from "vue-underscore";
import draggable from "vuedraggable";
import moment from "moment";
import "moment/locale/es";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { GET_MANDANTES } from "@/core/services/store/mandante.module";
import {
  GET_CAMPAIGNS_MANDANTE,
  GET_CAMPAIGN_TYPES,
  NEW_CAMPAIGN_DATA,
  UPDATE_CAMPAIGN_DATA,
  GET_CAMPAIGN_FIELDS_TYPES,
  NEW_CAMPAIGN_FIELDS,
  GET_ALL_CAMPAIGN_DATA,
  GET_CAMPAIGN_TREE_DATA_EXCEL,
  GET_ESTADOS_CAMPAIGN,
  UPDATE_CAMPAIGN_SCHEDULE,
  GET_CUSTOM_CERTFIEDS,
  CONFIG_CUSTOM_CERTFIED,
  UPDATE_END_DATE,
} from "@/core/services/store/campaign.module";
import {
  GET_JOBBERS,
  MASSIVE_JOBBER_MESSAGE,
  GET_CAMPAIGN_DAILY_MESSAGES_BY_JOBBER_ID,
} from "@/core/services/store/jobber.module";
import {
  POST_JOBBER_ASSIGNMENT,
  UNASSIGN_JOB_JOBBER,
  GET_ESTADOS_TASK,
  CHANGE_TASKS_STATE,
} from "@/core/services/store/task.module";
import { GET_CODIGOS } from "@/core/services/store/mandante.module";
import {
  GET_INALIENABLE_CALENDAR,
  GET_REGIONES,
  GET_REGIONES_PROVINCIAS_COMUNAS,
} from "@/core/services/store/global.module";
import { mapGetters } from "vuex";
import XLSX from "sheetjs-style";
import CampaignDetail from "../components/campaign/CampaignDetail.vue";
import TaskDetail from "../components/task/TaskDetail.vue";
import TaskListDialog from "../components/task/TaskListDialog.vue";
import JobberListDialog from "../components/jobber/JobberListDialog.vue";
import TasksSelectorByFieldContent from "../components/campaign/TasksSelectorByFieldContent.vue";
import DocumentRepository from "../components/campaign/DocumentRepository.vue";
import { getInfoXLSXCampaign } from "../../helpers/helper";
import { GET_WRONG_COMUNES } from "@/core/services/store/comune.module";
export default {
  name: "campaign",
  components: {
    draggable,
    CampaignDetail,
    TaskDetail,
    TaskListDialog,
    JobberListDialog,
    DocumentRepository,
    TasksSelectorByFieldContent,
  },
  data() {
    return {
      mandantes: [],
      mandanteID: null,
      regiones: [],
      aCodigos: [],
      isCampaignVisualilzation: false,
      dialog: false,
      dialogShowCampaign: false,
      dialogDeleteCampaign: false,
      headersCampaigns: [
        {
          text: "ID",
          align: "center",
          value: "campaignID",
          width: "5em",
          fixed: true,
        },
        {
          text: "Nombre de Campaña",
          value: "nombre",
        },
        {
          text: "Tipo de Campaña",
          value: "tipo",
          width: "13em",
          fixed: true,
        },
        {
          text: "Mandante",
          value: "mandanteNombre",
          width: "10em",
          fixed: true,
        },
        {
          text: "Tareas",
          value: "tareas",
          width: "8em",
          fixed: true,
        },
        {
          text: "F. Termino",
          value: "fechaTermino",
          width: "10em",
          fixed: true,
        },
        {
          text: "Dias",
          value: "dias",
          width: "7em",
          fixed: true,
        },
        {
          text: "Estado",
          value: "estado",
          width: "7em",
          fixed: true,
        },
        {
          text: "",
          value: "actions",
          width: "7em",
          fixed: true,
          sortable: false,
        },
      ],
      campaigns: [],
      campaignsFiltered: [],
      estado: null,
      estados: [],
      taskStates: [],
      statusID: null,
      status: [],
      campaignTypes: [],
      jobbers: [],
      search: "",
      campaign: {},
      wizard: null,
      wizardForm: {
        generalData: {
          campaignID: null,
          campaignName: "",
          campaignDescription: "",
          campaignType: "",
          campaignTypeDescription: "",
          campaignWorkCost: "",
          campaignDates: [],
          tasks: "",
          days: "",
          headerSchedulesDates: [
            {
              text: "Fecha",
              value: "fecha",
              sortable: false,
            },
            {
              text: "Desde",
              value: "horaDesde",
              sortable: false,
            },
            {
              text: "Hasta",
              value: "horaHasta",
              sortable: false,
            },
            {
              text: "Laborable",
              value: "laborable",
              sortable: false,
            },
          ],
          schedulesDates: [],
          coberturaZonal: [],
          coberturaZonalExcel: [],
          headersCoberturaZonal: [
            {
              text: "Región",
              value: "region",
            },
            {
              text: "Provincia",
              value: "provincia",
            },
            {
              text: "Comuna",
              value: "nombre",
            },
            {
              text: "Cant. Tareas",
              value: "tareas",
              align: "center",
              sortable: false,
            },
            {
              text: "Cant. Jobbers",
              value: "jobbers",
              align: "center",
              sortable: false,
            },
            {
              text: "",
              value: "warning",
              sortable: false,
            },
          ],
          headersCoberturaZonalExcel: [
            {
              text: "Región",
              value: "region",
            },
            {
              text: "Provincia",
              value: "provincia",
            },
            {
              text: "Comuna",
              value: "nombre",
            },
            {
              text: "Cant. Tareas",
              value: "tareas",
              align: "center",
              sortable: false,
            },
            {
              text: "Cant. Jobbers",
              value: "jobbers",
              align: "center",
              sortable: false,
            },
            {
              text: "Jobber",
              value: "jobber",
              sortable: false,
            },
          ],
          flyer: {
            haveFlyer: false,
            flyerShortCampaignName: "",
          },
        },
        requeriments: {
          jobberRequeriments: [],
        },
        fields: {
          action: "CREATE",
          file: null,
          importFile: null,
          fileRows: [],
          assignedJobsColumns: [],
          unassignmentJobsColumns: [],
          filteredUnassigmentJobbers: [],
          selectedAllUnassigmentTask: false,
          dialogUnassigmentJobs: false,
          jobber: null,
          fileColumns: [],
          fileColumnsNoSelected: [],
          fileColumnsSelected: [],
          headersFileRows: [],
          fileRowsSample: [],
          headersTreeRows: [
            {
              text: "Órden",
              value: "ordenCampo",
              align: "center",
              sortable: false,
            },
            { text: "Pregunta - campo", value: "pregunta", sortable: false },
            { text: "Tipo campo", value: "tipoCampo", sortable: false },
            {
              text: "Órden",
              value: "ordenOpcion",
              align: "center",
              sortable: false,
            },
            { text: "Respuesta", value: "descripcion", sortable: false },
            {
              text: "Próximo campo",
              value: "ordenProximoCampo",
              align: "center",
              sortable: false,
            },
            {
              text: "Descripción pregunta",
              value: "proximoCampo",
              sortable: false,
            },
          ],
          treeRows: [],
          headersJobbersRows: [
            {
              text: "Jobber",
              value: "jobber",
            },
            {
              text: "Tareas",
              value: "tareas",
            },
          ],
          jobbersRows: [],
          latitud: "",
          longitud: "",
          comuna: "",
          dialogFields: false,
          dialogImportFields: false,
          headersFinalFields: [
            {
              text: "Titulo",
              align: "start",
              value: "titulo",
            },
            { text: "Tipo de Campo", value: "tipo" },
            {
              text: "Codigo",
              align: "start",
              value: "code",
            },
            { text: "Acciones", value: "actions", sortable: false },
          ],
          finalFields: [],
          sortedFields: [],
          sortedFilesFields: [],
          sortedConfigFields: [],
          sortedConfigFieldsWithoutActualField: [],
          dialog: {
            title: "",
            type: null,
            fieldKey: "",
            hasOptions: false,
            fieldsTypes: [],
            dialogOptions: false,
            required: true,
            haveCode: false,
            code: null,
            option: {
              text: "",
              dialogLinkedField: false,
              linkedField: "",
              iFieldIndex: "",
              iOptionIndex: "",
              code: null,
              key: "",
              action: "CREATE",
            },
            headersFieldOptions: [
              {
                text: "Opcion",
                align: "start",
                value: "value",
              },
              {
                text: "Codigo",
                align: "start",
                value: "code",
              },
              { text: "Acciones", value: "actions", sortable: false },
            ],
            fieldOptions: [],
          },
          flyer: {
            flyerRutField: null,
          },
          customCertificate: {
            haveCertificate: false,
            certifieds: [],
            configs: [],
            selectedCertificate: null,
            selectedConfig: null,
          },
          barCode: {
            haveBarCode: false,
            field: null,
          },
          urlAntecedentes: "",
        },
        dailyMessages: {
          dialogMassiveJobberMessage: false,
          campaignJobbersAssigned: [],
          dialogJobberMessage: false,
          aCampaignDailyMessages: [],
          aHeaderCampaignDailyMessages: [
            {
              text: "Fecha",
              value: "fecha",
              sortable: false,
            },
            {
              text: "Mensaje",
              value: "mensaje",
              sortable: false,
            },
            {
              text: "Jobber",
              value: "nombreJobber",
              sortable: false,
            },
            {
              text: "Usuario",
              value: "nombreCreador",
              sortable: false,
            },
          ],
          sSearchCampaignDailyMessages: "",
          form: {
            groupJobberMessage: "",
            datepicker: false,
            sFechaMensaje: "",
            sFechaMensajeDatepicker: "",
          },
        },
      },
      editedIndex: -1,
      editedItem: {
        nombre: "",
        tipo: 0,
        cantidadTareas: 0,
        costoTarea: 0,
        fechaInicio: 0,
      },
      defaultItem: {
        nombre: "",
        tipo: 0,
        cantidadTareas: 0,
        costoTarea: 0,
        fechaInicio: 0,
      },
      initCampaign: false,
      inalienableCalendar: [],
      headersMessageToSend: [
        {
          text: "",
          value: "selected",
          sortable: false,
        },
        {
          text: "Nombre Jobber",
          value: "nombre",
        },
        {
          text: "Tareas Asignadas",
          value: "tareasAsignadas",
        },
        {
          text: "Tareas Realizadas",
          value: "tareasRealizadas",
        },
      ],
      selectedAllMessages: false,
      sSearchSinAsignar: null,
      sSearchAsignadas: null,
      isAdmin: true,
      updateEndDateValues: {
        dialog: false,
        datepicker: false,
        date: null,
        dateOriginal: null,
        campaignId: null,
      },
      comunes: {
        wrongs: [],
        rights: [],
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Campañas", route: "campaigns" },
    ]);

    this.mandanteID = this.currentUserPersonalInfo.mandanteID;
  },
  created() {
    moment.locale("es");
    this.initialize();
  },
  methods: {
    initialize() {
      let oVue = this;
      oVue.isAdmin = Number(oVue.currentUserPersonalInfo.perfil_id) === 1;
      oVue.getMandantes();
      oVue.getCampaigns();
      oVue.getTypes();
      oVue.getFieldsTypes();
      oVue.getJobbers();
      oVue.getEstados();
      oVue.getEstadosTareas();
      oVue.getStatus();
      oVue.getInalienableCalendar();
      oVue.getRegiones();
      oVue.getWrongComunes();
      oVue.getComunes();
    },
    getMandantes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_MANDANTES).then((mandantes) => {
        mandantes.unshift({ mandanteID: "", mandanteNombre: "" });
        oVue.mandantes = mandantes;
        KTUtil.removeLoading();
      });
    },
    showNewCampaignDialog() {
      let oVue = this;
      if (oVue.mandanteID !== "null") {
        oVue.dialog = true;
        oVue.initializeWizzard();
      } else {
        Swal.fire({
          title: "¡Alerta!",
          text: "Debe seleccionar un mandante para crear una campaña",
          icon: "warning",
        });
      }
    },
    async getImportExcelFile(ev) {
      KTUtil.setLoading();
      let oVue = this;
      let aConfirmation = await Swal.fire({
        title: "¿Está Seguro de seguir?.",
        text: "Se eliminara la configuración de campos actual.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "NO",
        confirmButtonText: "SI",
      });
      if (aConfirmation.isConfirmed) {
        oVue.wizardForm.fields.finalFields = [];
        oVue.wizardForm.fields.ImportFile =
          ev.target.files.length > 0 ? ev.target.files[0] : null;
        if (oVue.wizardForm.fields.ImportFile !== null) {
          let f = ev.target.files[0],
            reader = new FileReader();
          reader.onload = (e) => {
            let data = e.target.result;
            let wb = XLSX.read(data, { type: "array" });
            let jsonData = XLSX.utils.sheet_to_json(
              wb.Sheets[wb.SheetNames[0]]
            );

            const aTypes = oVue.wizardForm.fields.dialog.fieldsTypes;

            let iFieldHeader = -1;

            /* CREACIÓN DE CAMPOS */
            for (let iIndex in jsonData) {
              const aRow = jsonData[iIndex];
              if (
                typeof aRow.TITULO !== "undefined" &&
                typeof aRow.TIPO !== "undefined"
              ) {
                //CABECERA CAMPO
                const sTitulo = aRow.TITULO;
                const iTipoID = Number(aRow.TIPO);
                const iRequerido =
                  typeof aRow.REQUERIDO !== "undefined"
                    ? Number(aRow.REQUERIDO)
                    : 0;
                const aType = _.findWhere(aTypes, { id: iTipoID });

                if (typeof aType === "undefined") {
                  Swal.fire({
                    title: "Error!",
                    text:
                      "El tipo (" +
                      iTipoID +
                      ") del campo (" +
                      sTitulo +
                      ") no es valido",
                    icon: "warning",
                    confirmButtonClass: "btn btn-secondary",
                  });
                  KTUtil.removeLoading();
                  return false;
                }

                oVue.wizardForm.fields.finalFields.push({
                  titulo: sTitulo,
                  tipo: aType.descripcion,
                  tipoID: iTipoID,
                  opciones: [],
                  enlaceCampo: "",
                  requerido:
                    iTipoID === 3 || iTipoID === 8 ? true : Boolean(iRequerido),
                  code: null,
                  key: oVue.$uuid.v1(),
                });

                iFieldHeader++;
              } else {
                if (typeof aRow.OPCION !== "undefined") {
                  //OPCION

                  const aField =
                    oVue.wizardForm.fields.finalFields[iFieldHeader];
                  const aType = _.findWhere(aTypes, { id: aField.tipoID });

                  if (aType.opciones !== 1) {
                    Swal.fire({
                      title: "Error!",
                      text:
                        "El campo (" +
                        aField.titulo +
                        ") no tiene posibilidad de opciones",
                      icon: "warning",
                      confirmButtonClass: "btn btn-secondary",
                    });
                    KTUtil.removeLoading();
                    return false;
                  }

                  const sOption = aRow.OPCION;
                  const aOption = {
                    value: sOption,
                    enlaceCampo: "",
                    code: null,
                    key: oVue.$uuid.v1(),
                  };
                  oVue.wizardForm.fields.finalFields[
                    iFieldHeader
                  ].opciones.push(aOption);
                }
              }
            }

            iFieldHeader = -1;
            let iOptionField = 0;
            /* ACTUALIZACIÓN DE SEGUIMIENTO */
            for (let iIndex in jsonData) {
              const aRow = jsonData[iIndex];
              if (
                typeof aRow.TITULO !== "undefined" &&
                typeof aRow.TIPO !== "undefined"
              ) {
                //CABECERA CAMPO
                const sTitulo = aRow.TITULO;
                const iSiguiente = Number(aRow.SIGUIENTE);
                const iTipoID = Number(aRow.TIPO);
                const aType = _.findWhere(aTypes, { id: iTipoID });

                if (iSiguiente === -1) {
                  break;
                }

                if (typeof aType === "undefined") {
                  Swal.fire({
                    title: "Error!",
                    text:
                      "El tipo (" +
                      iTipoID +
                      ") del campo (" +
                      sTitulo +
                      ") no es valido",
                    icon: "warning",
                    confirmButtonClass: "btn btn-secondary",
                  });
                  KTUtil.removeLoading();
                  return false;
                }

                iFieldHeader++;
                iOptionField = 0;
                if (iTipoID !== 3) {
                  if (iSiguiente <= oVue.wizardForm.fields.finalFields.length) {
                    const aNextField =
                      oVue.wizardForm.fields.finalFields[iSiguiente - 1];
                    oVue.wizardForm.fields.finalFields[
                      iFieldHeader
                    ].enlaceCampo = aNextField.key;
                  } else {
                    Swal.fire({
                      title: "Error!",
                      text:
                        "El campo (" +
                        sTitulo +
                        ") no puede apuntar al campo (" +
                        iSiguiente +
                        ") ya que el campo no existe",
                      icon: "warning",
                      confirmButtonClass: "btn btn-secondary",
                    });
                    KTUtil.removeLoading();
                    return false;
                  }
                }
              } else {
                if (typeof aRow.OPCION !== "undefined") {
                  //OPCION
                  const aField =
                    oVue.wizardForm.fields.finalFields[iFieldHeader];
                  const aType = _.findWhere(aTypes, { id: aField.tipoID });
                  const iSiguiente = Number(aRow.SIGUIENTE);
                  const iTipoID = Number(aType.id);

                  if (iSiguiente === -1) {
                    break;
                  }

                  if (aType.opciones !== 1) {
                    Swal.fire({
                      title: "Error!",
                      text:
                        "El campo (" +
                        aField.titulo +
                        ") no tiene posibilidad de opciones",
                      icon: "warning",
                      confirmButtonClass: "btn btn-secondary",
                    });
                    KTUtil.removeLoading();
                    return false;
                  }

                  const sOption = aRow.OPCION;
                  if (iTipoID === 3) {
                    if (
                      iSiguiente <= oVue.wizardForm.fields.finalFields.length
                    ) {
                      const aNextField =
                        oVue.wizardForm.fields.finalFields[iSiguiente - 1];
                      oVue.wizardForm.fields.finalFields[iFieldHeader].opciones[
                        iOptionField
                      ].enlaceCampo = aNextField.key;
                    } else {
                      Swal.fire({
                        title: "Error!",
                        text:
                          "La opción (" +
                          sOption +
                          ") no puede apuntar al campo (" +
                          iSiguiente +
                          ") ya que el campo no existe",
                        icon: "warning",
                        confirmButtonClass: "btn btn-secondary",
                      });
                      KTUtil.removeLoading();
                      return false;
                    }
                  }

                  iOptionField++;
                }
              }
            }

            KTUtil.removeLoading();
          };
          reader.readAsArrayBuffer(f);
        }
      } else {
        KTUtil.removeLoading();
      }
      this.$refs.importExcelFile.reset();
      oVue.wizardForm.fields.dialogImportFields = false;
    },
    getExcelFile(ev) {
      KTUtil.setLoading();
      let oVue = this;
      oVue.wizardForm.fields.file =
        ev.target.files.length > 0 ? ev.target.files[0] : null;
      if (oVue.wizardForm.fields.file !== null) {
        oVue.wizardForm.fields.fileColumns = [];
        oVue.wizardForm.fields.fileColumnsNoSelected = [];
        oVue.wizardForm.fields.fileColumnsSelected = [];
        oVue.wizardForm.fields.fileRows = [];
        oVue.wizardForm.fields.headersFileRows = [];
        oVue.wizardForm.fields.fileRowsSample = [];

        let f = ev.target.files[0],
          reader = new FileReader();
        reader.onload = (e) => {
          let data = e.target.result;
          let wb = XLSX.read(data, {
            type: "array",
            cellDates: true,
            dateNF: "dd/mm/yyyy;@",
            raw: true,
          });
          let jsonData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
            defval: "",
          });
          oVue.wizardForm.fields.fileRows = jsonData;
          const iCantSample =
            oVue.wizardForm.fields.fileRows.length >= 5
              ? 5
              : oVue.wizardForm.fields.fileRows.length;
          for (let i = 0; i < iCantSample; i++) {
            oVue.wizardForm.fields.fileRowsSample.push(
              oVue.wizardForm.fields.fileRows[i]
            );
          }
          oVue.getExcelColumns();
          KTUtil.removeLoading();
        };
        reader.readAsArrayBuffer(f);
      } else {
        Swal.fire({
          title: "¡Alerta!",
          text: "¿Desea eliminar el archivo de tareas?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "NO",
          confirmButtonText: "SI",
        }).then((response) => {
          if (response.isConfirmed) {
            oVue.wizardForm.fields.fileColumns = [];
            oVue.wizardForm.fields.fileColumnsNoSelected = [];
            oVue.wizardForm.fields.fileColumnsSelected = [];
            oVue.wizardForm.fields.fileRows = [];
            oVue.wizardForm.fields.headersFileRows = [];
            oVue.wizardForm.fields.fileRowsSample = [];
          }
          KTUtil.removeLoading();
        });
      }
    },

    getExcelColumns() {
      let oVue = this;
      const aFileRows = oVue.wizardForm.fields.fileRows;
      if (aFileRows.length > 0) {
        const aFirstRow = aFileRows[0];

        let iRowNum = 0;

        oVue.wizardForm.fields.headersFileRows.push({
          text: "Mapa",
          value: "map",
        });

        for (const sColumnName in aFirstRow) {
          const sKey = sColumnName + "_" + iRowNum;

          oVue.wizardForm.fields.fileColumns.push({
            name: sColumnName,
            key: sKey,
          });
          oVue.wizardForm.fields.headersFileRows.push({
            text: sColumnName,
            value: sColumnName,
          });

          iRowNum++;
        }
        oVue.wizardForm.fields.fileColumnsNoSelected =
          oVue.wizardForm.fields.fileColumns;
      }
    },

    selectColumn(aColumn) {
      let oVue = this;

      aColumn.position = 0;

      oVue.wizardForm.fields.fileColumnsSelected.push(aColumn);

      const aNewFileColumns = _.without(
        oVue.wizardForm.fields.fileColumnsNoSelected,
        _.findWhere(oVue.wizardForm.fields.fileColumnsNoSelected, {
          key: aColumn.key,
        })
      );

      oVue.wizardForm.fields.fileColumnsNoSelected = aNewFileColumns;
    },

    unSelectColumn(aColumn) {
      let oVue = this;

      const aOriginalColumn = _.findWhere(oVue.wizardForm.fields.fileColumns, {
        key: aColumn.key,
      });

      oVue.wizardForm.fields.fileColumnsNoSelected.push(aOriginalColumn);

      const aNewFileColumns = _.without(
        oVue.wizardForm.fields.fileColumnsSelected,
        _.findWhere(oVue.wizardForm.fields.fileColumnsSelected, {
          key: aOriginalColumn.key,
        })
      );

      oVue.wizardForm.fields.fileColumnsSelected = aNewFileColumns;
    },
    showMap() {
      alert("Mostrar mapa con latitud y longitud seleccionada");
    },

    closeFieldForm() {
      let oVue = this;
      oVue.wizardForm.fields.dialog.title = "";
      oVue.wizardForm.fields.dialog.type = "";
      oVue.wizardForm.fields.dialog.required = true;
      oVue.wizardForm.fields.dialog.fieldOptions = [];
      oVue.wizardForm.fields.dialogFields = false;
      oVue.wizardForm.fields.dialog.code = null;
    },
    saveField() {
      let oVue = this;

      const sTitle = oVue.wizardForm.fields.dialog.title;
      const iType = oVue.wizardForm.fields.dialog.type;
      const aTypes = oVue.wizardForm.fields.dialog.fieldsTypes;
      const bRequired = oVue.wizardForm.fields.dialog.required;
      const iCode = oVue.wizardForm.fields.dialog.code;

      if (sTitle !== "") {
        if (iType !== "") {
          const aType = _.findWhere(aTypes, { id: iType });

          switch (oVue.wizardForm.fields.action) {
            case "CREATE":
              oVue.wizardForm.fields.finalFields.push({
                titulo: sTitle,
                tipo: aType.descripcion,
                tipoID: iType,
                opciones: oVue.wizardForm.fields.dialog.fieldOptions,
                enlaceCampo: "",
                requerido: bRequired,
                code: iType !== 3 && iType !== 4 ? iCode : null,
                key: oVue.$uuid.v1(),
              });
              break;
            case "UPDATE":
              //eslint-disable-next-line
              const aOldDataField = _.findWhere(
                oVue.wizardForm.fields.finalFields,
                { key: oVue.wizardForm.fields.fieldKey }
              );
              //eslint-disable-next-line
              const aNewField = {
                titulo: sTitle,
                tipo: aType.descripcion,
                tipoID: iType,
                opciones: oVue.wizardForm.fields.dialog.fieldOptions,
                enlaceCampo: aOldDataField.enlaceCampo,
                requerido: bRequired,
                code: iCode,
                key: aOldDataField.key,
              };
              //eslint-disable-next-line
              const iIndex = oVue.wizardForm.fields.finalFields.findIndex(
                (aField) => aField.key === oVue.wizardForm.fields.fieldKey
              );
              oVue.wizardForm.fields.finalFields.splice(iIndex, 1, aNewField);

              break;
          }

          oVue.wizardForm.fields.dialogFields = false;
          oVue.wizardForm.fields.dialog.title = "";
          oVue.wizardForm.fields.dialog.type = "";
          oVue.wizardForm.fields.dialog.required = true;
          oVue.wizardForm.fields.dialog.fieldOptions = [];
          oVue.wizardForm.fields.dialog.code = null;
          oVue.wizardForm.fields.action = "CREATE";
          oVue.wizardForm.fields.dialog.haveCode = false;
        } else {
          Swal.fire({
            title: "Error!",
            text: "Debe ingresar un tipo de campo",
            icon: "warning",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: "Debe ingresar un titulo para el campo",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },

    closeFieldOptionForm() {
      let oVue = this;
      oVue.wizardForm.fields.dialog.option.text = "";
      oVue.wizardForm.fields.dialog.dialogOptions = false;
      oVue.wizardForm.fields.dialog.code = null;
    },

    saveOption() {
      let oVue = this;

      const sOption = oVue.wizardForm.fields.dialog.option.text;
      const iCode = oVue.wizardForm.fields.dialog.option.code;
      let bCanSave = true;

      if (oVue.wizardForm.fields.dialog.haveCode) {
        if (iCode === null) {
          bCanSave = false;
          Swal.fire({
            title: "Error!",
            text: "Debe seleccionar un codigo",
            icon: "warning",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      }

      if (bCanSave) {
        if (sOption !== "") {
          switch (oVue.wizardForm.fields.dialog.option.action) {
            case "CREATE":
              oVue.wizardForm.fields.dialog.fieldOptions.push({
                value: sOption,
                enlaceCampo: "",
                code: oVue.wizardForm.fields.dialog.option.code,
                key: oVue.$uuid.v1(),
              });
              break;
            case "UPDATE":
              //eslint-disable-next-line
              const aOldDataOption = _.findWhere(
                oVue.wizardForm.fields.dialog.fieldOptions,
                { key: oVue.wizardForm.fields.dialog.option.key }
              );
              //eslint-disable-next-line
              const aNewOption = {
                value: sOption,
                enlaceCampo: aOldDataOption.enlaceCampo,
                code: iCode,
                key: aOldDataOption.key,
              };
              //eslint-disable-next-line
              const iIndex =
                oVue.wizardForm.fields.dialog.fieldOptions.findIndex(
                  (aOption) =>
                    aOption.key === oVue.wizardForm.fields.dialog.option.key
                );
              oVue.wizardForm.fields.dialog.fieldOptions.splice(
                iIndex,
                1,
                aNewOption
              );

              break;
          }

          /* oVue.wizardForm.fields.dialog.fieldOptions.push({
            value: oVue.wizardForm.fields.dialog.option.text,
            enlaceCampo: "",
            code: oVue.wizardForm.fields.dialog.option.code,
            key: oVue.$uuid.v1()
          }) */
          oVue.wizardForm.fields.dialog.dialogOptions = false;
          oVue.wizardForm.fields.dialog.option.text = "";
          oVue.wizardForm.fields.dialog.option.code = null;
          oVue.wizardForm.fields.dialog.option.action = "CREATE";
        } else {
          Swal.fire({
            title: "Error!",
            text: "Debe ingresar una opción",
            icon: "warning",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      }
    },

    updateOption(aOption) {
      let oVue = this;

      oVue.wizardForm.fields.dialog.dialogOptions = true;
      oVue.wizardForm.fields.dialog.option.text = aOption.value;
      oVue.wizardForm.fields.dialog.option.code = aOption.code;
      oVue.wizardForm.fields.dialog.option.key = aOption.key;
      oVue.wizardForm.fields.dialog.option.action = "UPDATE";
    },

    codeDescription(iCode) {
      let oVue = this;
      if (iCode !== null && typeof iCode !== "undefined") {
        const aCode = _.findWhere(oVue.aCodigos, { id: iCode });
        return aCode.descripcion;
      } else {
        return "";
      }
    },

    deleteOption(aOption) {
      let oVue = this;
      Swal.fire({
        title: "¡Alerta!",
        text: "¿Desea eliminar la opción seleccionada?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "NO",
        confirmButtonText: "SI",
      }).then((response) => {
        if (response.isConfirmed) {
          const aNewOptions = _.without(
            oVue.wizardForm.fields.dialog.fieldOptions,
            _.findWhere(oVue.wizardForm.fields.dialog.fieldOptions, {
              key: aOption.key,
            })
          );
          oVue.wizardForm.fields.dialog.fieldOptions = aNewOptions;
        }
      });
    },

    updateField(aField) {
      let oVue = this;
      oVue.wizardForm.fields.dialog.title = aField.titulo;
      oVue.wizardForm.fields.dialog.type = aField.tipoID;
      oVue.wizardForm.fields.dialog.fieldOptions = aField.opciones;
      oVue.wizardForm.fields.fieldKey = aField.key;
      oVue.wizardForm.fields.dialog.required = aField.requerido;
      oVue.wizardForm.fields.dialogFields = true;
      oVue.wizardForm.fields.action = "UPDATE";

      if (aField.code !== null) {
        oVue.wizardForm.fields.dialog.haveCode = true;
        oVue.wizardForm.fields.dialog.code = aField.code;
      } else {
        for (let iIndex in aField.opciones) {
          const aOption = aField.opciones[iIndex];
          if (aOption.code !== null) {
            oVue.wizardForm.fields.dialog.haveCode = true;
          }
        }
      }
    },

    deleteField(aField) {
      let oVue = this;
      Swal.fire({
        title: "¡Alerta!",
        text: "¿Desea eliminar el campo seleccionado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "NO",
        confirmButtonText: "SI",
      }).then((response) => {
        if (response.isConfirmed) {
          const aNewFields = _.without(
            oVue.wizardForm.fields.finalFields,
            _.findWhere(oVue.wizardForm.fields.finalFields, { key: aField.key })
          );
          oVue.wizardForm.fields.finalFields = aNewFields;
        }
      });
    },

    changeOptionLinkedField(iFieldIndex, iOptionIndex) {
      let oVue = this;

      oVue.wizardForm.fields.dialog.option.iFieldIndex = iFieldIndex;
      oVue.wizardForm.fields.dialog.option.iOptionIndex = iOptionIndex;

      oVue.wizardForm.fields.dialog.option.linkedField =
        oVue.wizardForm.fields.sortedConfigFields[iFieldIndex].opciones[
          iOptionIndex
        ].enlaceCampo;

      oVue.wizardForm.fields.sortedConfigFieldsWithoutActualField =
        oVue.getLikingFields(iFieldIndex);

      oVue.wizardForm.fields.dialog.option.dialogLinkedField = true;
    },

    updateOptionLinkedField() {
      let oVue = this;
      const iFieldIndex = oVue.wizardForm.fields.dialog.option.iFieldIndex;
      const iOptionIndex = oVue.wizardForm.fields.dialog.option.iOptionIndex;
      const sLinkedField = oVue.wizardForm.fields.dialog.option.linkedField;
      const sConfigFieldKey =
        oVue.wizardForm.fields.sortedConfigFields[iFieldIndex].key;

      if (sLinkedField !== sConfigFieldKey) {
        if (iOptionIndex !== null) {
          oVue.wizardForm.fields.sortedConfigFields[iFieldIndex].opciones[
            iOptionIndex
          ].enlaceCampo = sLinkedField;
        } else {
          oVue.wizardForm.fields.sortedConfigFields[iFieldIndex].enlaceCampo =
            sLinkedField;
        }

        for (let i = 0; i < oVue.wizardForm.fields.finalFields; i++) {
          const aCurrentField = oVue.wizardForm.fields.finalFields[i];
          if (aCurrentField.key === sConfigFieldKey) {
            if (iOptionIndex !== null) {
              oVue.wizardForm.fields.finalFields[i].opciones[
                iOptionIndex
              ].enlaceCampo = oVue.wizardForm.fields.dialog.option.linkedField;
            } else {
              oVue.wizardForm.fields.finalFields[i].enlaceCampo =
                oVue.wizardForm.fields.dialog.option.linkedField;
            }
          }
        }

        oVue.wizardForm.fields.dialog.option.iFieldIndex = "";
        oVue.wizardForm.fields.dialog.option.iOptionIndex = "";
        oVue.wizardForm.fields.dialog.option.linkedField = "";
        oVue.wizardForm.fields.dialog.option.dialogLinkedField = false;
      } else {
        Swal.fire({
          title: "Error!",
          text: "El campo de Destino no puede ser el mismo de origen",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },

    changeFieldLinkedField(iFieldIndex) {
      let oVue = this;

      oVue.wizardForm.fields.dialog.option.iFieldIndex = iFieldIndex;
      oVue.wizardForm.fields.dialog.option.iOptionIndex = null;
      oVue.wizardForm.fields.dialog.option.linkedField =
        oVue.wizardForm.fields.sortedConfigFields[iFieldIndex].enlaceCampo;

      oVue.wizardForm.fields.sortedConfigFieldsWithoutActualField =
        oVue.getLikingFields(iFieldIndex);

      oVue.wizardForm.fields.dialog.option.dialogLinkedField = true;
    },

    getLikingFields(iFieldIndex) {
      let oVue = this;
      let aFields = [];

      for (
        let i = iFieldIndex + 1;
        i < oVue.wizardForm.fields.sortedConfigFields.length;
        i++
      ) {
        aFields.push(oVue.wizardForm.fields.sortedConfigFields[i]);
      }

      return aFields;
    },
    sortConfigFieldsCallback() {
      let oVue = this;
      const iLastIndex = oVue.wizardForm.fields.sortedConfigFields.length - 1;
      oVue.wizardForm.fields.sortedConfigFields[iLastIndex].enlaceCampo = "";
      for (
        let i = 0;
        i < oVue.wizardForm.fields.sortedConfigFields[iLastIndex].opciones - 1;
        i++
      ) {
        oVue.wizardForm.fields.sortedConfigFields[iLastIndex].opciones[i] = "";
      }

      return true;
    },

    initializeWizzard() {
      let oVue = this;

      oVue.getCustomCertificates(oVue.mandanteID);

      setTimeout(() => {
        // Initialize form wizard
        oVue.wizard = new KTWizard("campaignWizzard", {
          startStep: 1, // initial active step number
          clickableSteps: true, // allow step clicking
        });

        let wizard = oVue.wizard;

        wizard.on("beforeNext", function (/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        });

        // Change event
        wizard.on("change", async function (wizardObj) {
          let bCanNext = false;

          const iCurrentStep = Number(wizard.currentStep);
          const iNextStep = Number(wizard.newStep);

          //eslint-disable-next-line no-empty
          if (iCurrentStep + 1 < iNextStep) {
          } else {
            if (iNextStep < iCurrentStep) {
              bCanNext = true;
            }
          }

          switch (iCurrentStep) {
            case 1:
              if (oVue.wizardForm.generalData.campaignName !== "") {
                if (oVue.wizardForm.generalData.campaignDescription !== "") {
                  if (oVue.wizardForm.generalData.campaignType !== "") {
                    if (oVue.wizardForm.generalData.campaignWorkCost !== "") {
                      if (
                        oVue.wizardForm.generalData.campaignDates.length === 2
                      ) {
                        bCanNext = true;

                        const iCampaignID =
                          oVue.wizardForm.generalData.campaignID;
                        const bCreateCampaign = iCampaignID === null;
                        let sAlertText = "";
                        if (bCreateCampaign) {
                          sAlertText =
                            "Al aceptar La campaña se creará en estado 'Incompleta'";
                        } else {
                          sAlertText =
                            "Al aceptar La campaña se actualizarán los datos generales de la campaña";
                        }
                        let aCreateUpdateCampaign = await Swal.fire({
                          title: "¿Está Seguro de seguir?",
                          text: sAlertText,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          cancelButtonText: "NO",
                          confirmButtonText: "SI",
                        });
                        if (aCreateUpdateCampaign.isConfirmed) {
                          let aData = {
                            tipo: oVue.wizardForm.generalData.campaignType,
                            nombre: oVue.wizardForm.generalData.campaignName,
                            descripcion:
                              oVue.wizardForm.generalData.campaignDescription,
                            costoTarea:
                              oVue.wizardForm.generalData.campaignWorkCost,
                            fechaInicio:
                              oVue.wizardForm.generalData.campaignDates[0],
                            fechaTermino:
                              oVue.wizardForm.generalData.campaignDates[1],
                            schedule:
                              oVue.wizardForm.generalData.schedulesDates,
                            haveFlyer:
                              oVue.wizardForm.generalData.flyer.haveFlyer,
                            flyerShortCampaignName:
                              oVue.wizardForm.generalData.flyer
                                .flyerShortCampaignName,
                          };

                          if (bCreateCampaign) {
                            (aData.mandante = oVue.mandanteID),
                              (aData.usuario =
                                oVue.currentUserPersonalInfo.usuarioID);
                            KTUtil.setLoading();
                            oVue
                              .newCampaign(aData)
                              .then((aNewCampaignResponse) => {
                                KTUtil.removeLoading();
                                if (aNewCampaignResponse.result) {
                                  const aCampaign = aNewCampaignResponse.data;
                                  oVue.wizardForm.generalData.campaignID =
                                    aCampaign.campaignID;
                                  Swal.fire({
                                    title: "¡Enhorabuena!",
                                    text: "La campaña fue creada",
                                    icon: "success",
                                    confirmButtonClass: "btn btn-secondary",
                                  });
                                } else {
                                  Swal.fire({
                                    title: "Error al crear la campaña",
                                    text: aNewCampaignResponse.message,
                                    icon: "error",
                                    confirmButtonClass: "btn btn-secondary",
                                  });
                                  wizardObj.goTo(wizardObj.lastStep);
                                }
                              });
                          } else {
                            aData.campaign = iCampaignID;
                            KTUtil.setLoading();
                            oVue
                              .updateCampaign(aData)
                              .then((aUpdateCampaignResponse) => {
                                KTUtil.removeLoading();
                                if (aUpdateCampaignResponse.result) {
                                  Swal.fire({
                                    title: "¡Enhorabuena!",
                                    text: "Los datos generales de la campaña fueron actualizados",
                                    icon: "success",
                                    confirmButtonClass: "btn btn-secondary",
                                  });
                                } else {
                                  Swal.fire({
                                    title: "Error al actualizar la campaña",
                                    text: aUpdateCampaignResponse.message,
                                    icon: "error",
                                    confirmButtonClass: "btn btn-secondary",
                                  });
                                  wizardObj.goTo(wizardObj.lastStep);
                                }
                              });
                          }
                        } else {
                          wizardObj.goTo(wizardObj.lastStep);
                        }
                      } else {
                        Swal.fire({
                          title: "",
                          text: "Debe indicar la Fecha de inicio y termino de la campaña.",
                          icon: "error",
                          confirmButtonClass: "btn btn-secondary",
                        });
                      }
                    } else {
                      Swal.fire({
                        title: "",
                        text: "Debe indicar el costo por tarea.",
                        icon: "error",
                        confirmButtonClass: "btn btn-secondary",
                      });
                    }
                  } else {
                    Swal.fire({
                      title: "",
                      text: "Debe indicar el tipo de la campaña.",
                      icon: "error",
                      confirmButtonClass: "btn btn-secondary",
                    });
                  }
                } else {
                  Swal.fire({
                    title: "",
                    text: "Debe indicar la descripción de la campaña.",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                  });
                }
              } else {
                Swal.fire({
                  title: "",
                  text: "Debe indicar el nombre de la campaña.",
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                });
              }

              break;
            case 2:
              if (oVue.wizardForm.fields.fileRows.length > 0) {
                if (oVue.wizardForm.fields.latitud !== "") {
                  if (oVue.wizardForm.fields.longitud !== "") {
                    if (
                      oVue.wizardForm.fields.latitud !==
                      oVue.wizardForm.fields.longitud
                    ) {
                      bCanNext = true;
                    } else {
                      bCanNext = false;
                      Swal.fire({
                        title: "",
                        text: "Los campos Latitud y Longitud deben ser distintos",
                        icon: "error",
                        confirmButtonClass: "btn btn-secondary",
                      });
                    }
                  } else {
                    Swal.fire({
                      title: "",
                      text: "Debe indicar el campo de la longitud de la tarea",
                      icon: "error",
                      confirmButtonClass: "btn btn-secondary",
                    });
                  }
                } else {
                  Swal.fire({
                    title: "",
                    text: "Debe indicar el campo de la Latitud de la tarea",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                  });
                }
              } else {
                bCanNext = true;
              }
              if (oVue.wizardForm.generalData.flyer.haveFlyer) {
                if (oVue.wizardForm.fields.flyer.flyerRutField === null) {
                  bCanNext = false;
                  Swal.fire({
                    title: "",
                    text: "Debe indicar el campo de de Rut para identificarlo en el volante",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                  });
                }
              }
              if (bCanNext) {
                if (oVue.wizardForm.fields.finalFields.length > 0) {
                  oVue.wizardForm.fields.sortedFields = [];
                  oVue.wizardForm.fields.sortedFilesFields = [];
                  oVue.wizardForm.fields.sortedConfigFields = [];

                  if (oVue.wizardForm.fields.fileColumnsSelected.length > 0) {
                    const aTypes = oVue.wizardForm.fields.dialog.fieldsTypes;

                    for (
                      let i = 0;
                      i < oVue.wizardForm.fields.fileColumnsSelected.length;
                      i++
                    ) {
                      const aType = _.findWhere(aTypes, { id: 1 });
                      oVue.wizardForm.fields.sortedFilesFields.push({
                        titulo:
                          oVue.wizardForm.fields.fileColumnsSelected[i].name,
                        tipo: aType.descripcion,
                        tipoID: aType.id,
                        opciones: [],
                        requerido: true,
                        key: oVue.$uuid.v1(),
                        origin: "FILE",
                      });
                    }
                    for (
                      let i = 0;
                      i < oVue.wizardForm.fields.finalFields.length;
                      i++
                    ) {
                      let aField = oVue.wizardForm.fields.finalFields[i];
                      aField.origin = "CONFIG";
                      oVue.wizardForm.fields.sortedConfigFields.push(aField);
                    }

                    oVue.validateComunes();

                    bCanNext = true;
                  } else {
                    bCanNext = false;
                    Swal.fire({
                      title: "",
                      text: "Debe seleccionar almenos 1 columna de las tareas.",
                      icon: "error",
                      confirmButtonClass: "btn btn-secondary",
                    });
                  }
                } else {
                  bCanNext = false;
                  Swal.fire({
                    title: "",
                    text: "Debe configurar al menos 1 campo",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                  });
                }
              }
              break;
            case 3:
              if (iNextStep === 5) {
                let bError = false;

                for (
                  let i = 0;
                  i < oVue.wizardForm.fields.sortedConfigFields.length - 1;
                  i++
                ) {
                  const aField = oVue.wizardForm.fields.sortedConfigFields[i];
                  const sKey = aField.key;
                  const fieldEnlaceCampo = aField.enlaceCampo;
                  const bFieldHasOptions = oVue.fieldHasOptions(sKey);
                  const iFieldTypeID = aField.tipoID;
                  if (bError === false) {
                    if (
                      i <
                      oVue.wizardForm.fields.sortedConfigFields.length - 1
                    ) {
                      if (bFieldHasOptions && iFieldTypeID === 3) {
                        const aOptions = aField.opciones;
                        const aOptionsWithoutLinkedField = _.where(aOptions, {
                          enlaceCampo: "",
                        });

                        if (aOptionsWithoutLinkedField.length > 0) {
                          bError = true;
                        }
                      } else {
                        if (fieldEnlaceCampo === "") {
                          bError = true;
                        }
                      }
                    }
                  } else {
                    break;
                  }
                }

                if (bError === false) {
                  bCanNext = true;
                } else {
                  Swal.fire({
                    title: "",
                    text: "Debe configurar los enlaces de los campos y las alternativas",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                  });
                }
              } else {
                bCanNext = true;
              }

              break;
          }

          if (bCanNext === false) {
            wizardObj.stop();
          }

          setTimeout(() => {
            KTUtil.scrollTop();
          }, 500);
        });
      }, 100);
    },

    sendForm: async function (e) {
      e.preventDefault();
      let oVue = this;
      let aCreateCampaignFields = await Swal.fire({
        title: "¿Está Seguro de seguir?",
        text: "Al Aceptar se grabaran los campos y no podrán modificarse mas adelante",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "NO",
        confirmButtonText: "SI",
      });
      if (aCreateCampaignFields.isConfirmed) {
        for (
          let i = 0;
          i < oVue.wizardForm.fields.sortedFilesFields.length;
          i++
        ) {
          oVue.wizardForm.fields.sortedFields.push(
            oVue.wizardForm.fields.sortedFilesFields[i]
          );
        }
        for (
          let i = 0;
          i < oVue.wizardForm.fields.sortedConfigFields.length;
          i++
        ) {
          oVue.wizardForm.fields.sortedFields.push(
            oVue.wizardForm.fields.sortedConfigFields[i]
          );
        }

        if (oVue.wizardForm.generalData.flyer.haveFlyer) {
          const sKey = oVue.$uuid.v1();

          const aFieldType = _.findWhere(
            oVue.wizardForm.fields.dialog.fieldsTypes,
            { id: 1 }
          );

          const aFolioField = {
            enlaceCampo: "",
            key: sKey,
            opciones: [],
            origin: "CONFIG",
            requerido: true,
            tipo: aFieldType.descripcion,
            tipoID: 1,
            titulo: "Ingrese el Folio",
          };
          const iCantFields = oVue.wizardForm.fields.sortedFields.length;
          const aLastField =
            oVue.wizardForm.fields.sortedFields[iCantFields - 1];
          switch (aLastField.tipoID) {
            case 3:
              for (let i = 0; i <= aLastField.opciones.length - 1; i++) {
                oVue.wizardForm.fields.sortedFields[iCantFields - 1].opciones[
                  i
                ].enlaceCampo = sKey;
              }
              break;
            default:
              oVue.wizardForm.fields.sortedFields[iCantFields - 1].enlaceCampo =
                sKey;
          }

          oVue.wizardForm.fields.sortedFields.push(aFolioField);
        }

        const aData = {
          campaign: oVue.wizardForm.generalData.campaignID,
          customCertificate: {
            haveCertificate:
              oVue.wizardForm.fields.customCertificate.haveCertificate,
            selectedCertificate:
              oVue.wizardForm.fields.customCertificate.selectedCertificate,
            selectedConfig:
              oVue.wizardForm.fields.customCertificate.selectedConfig,
          },
          rows: oVue.wizardForm.fields.fileRows,
          fields: oVue.wizardForm.fields.sortedFields,
          latitud: oVue.getFromFileRows(oVue.wizardForm.fields.latitud),
          longitud: oVue.getFromFileRows(oVue.wizardForm.fields.longitud),
          comuna: oVue.getFromFileRows(oVue.wizardForm.fields.comuna),
          rut: oVue.getFromFileRows(oVue.wizardForm.fields.flyer.flyerRutField),
          codigoBarra: oVue.wizardForm.fields.barCode.haveBarCode
            ? oVue.getFromFileRows(oVue.wizardForm.fields.barCode.field)
            : null,
          urlAntecedentes: oVue.getFromFileRows(
            oVue.wizardForm.fields.urlAntecedentes
          ),
        };

        console.log(aData);

        KTUtil.setLoading();
        oVue.newFields(aData).then(async (aNewCampaignFields) => {
          KTUtil.removeLoading();
          if (aNewCampaignFields.result) {
            await oVue.getCampaigns();
            oVue.cleanScreen();
          } else {
            Swal.fire({
              title: "¡Error!",
              text: aNewCampaignFields.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        });
      }
    },

    cleanScreen() {
      let oVue = this;

      oVue.dialog = false;
      if (oVue.wizard !== null) {
        oVue.wizard.goTo(1);
      }

      oVue.campaign = {};

      oVue.isCampaignVisualilzation = false;

      oVue.wizardForm.generalData.campaignID = null;
      oVue.wizardForm.generalData.campaignName = "";
      oVue.wizardForm.generalData.campaignDescription = "";
      oVue.wizardForm.generalData.campaignType = "";
      oVue.wizardForm.generalData.campaignTypeDescription = "";
      oVue.wizardForm.generalData.campaignWorkCost = "";
      oVue.wizardForm.generalData.campaignDates = [];
      oVue.wizardForm.generalData.schedulesDates = [];
      oVue.wizardForm.generalData.coberturaZonal = [];
      oVue.wizardForm.generalData.coberturaZonalExcel = [];
      oVue.wizardForm.generalData.flyer.haveFlyer = false;
      oVue.wizardForm.generalData.flyer.flyerShortCampaignName = "";

      oVue.wizardForm.fields.file = null;
      oVue.wizardForm.fields.fileRows = [];
      oVue.wizardForm.fields.fileColumns = [];
      oVue.wizardForm.fields.fileColumnsNoSelected = [];
      oVue.wizardForm.fields.fileColumnsSelected = [];
      oVue.wizardForm.fields.headersFileRows = [];
      oVue.wizardForm.fields.fileRowsSample = [];
      oVue.wizardForm.fields.latitud = "";
      oVue.wizardForm.fields.longitud = "";
      oVue.wizardForm.fields.comuna = "";
      oVue.wizardForm.fields.urlAntecedentes = "";
      oVue.wizardForm.fields.finalFields = [];
      oVue.wizardForm.fields.sortedFields = [];
      oVue.wizardForm.fields.sortedFilesFields = [];
      oVue.wizardForm.fields.sortedConfigFields = [];
      oVue.wizardForm.fields.sortedConfigFieldsWithoutActualField = [];
      oVue.wizardForm.fields.dialog.title = "";
      oVue.wizardForm.fields.dialog.type = null;
      oVue.wizardForm.fields.dialog.fieldKey = "";
      oVue.wizardForm.fields.dialog.hasOptions = false;
      oVue.wizardForm.fields.dialog.dialogOptions = false;
      oVue.wizardForm.fields.dialog.fieldOptions = [];
      oVue.wizardForm.fields.assignedJobsColumns = [];
      oVue.wizardForm.fields.unassignmentJobsColumns = [];
      oVue.wizardForm.fields.dialogUnassigmentJobs = false;
      oVue.wizardForm.fields.jobber = null;
      oVue.wizardForm.fields.flyer.flyerRutField = null;
      oVue.wizardForm.fields.customCertificate.haveCertificate = false;
      oVue.wizardForm.fields.customCertificate.certifieds = [];
      oVue.wizardForm.fields.customCertificate.configs = [];
      oVue.wizardForm.fields.customCertificate.selectedCertificate = null;
      oVue.wizardForm.fields.customCertificate.selectedConfig = null;
    },

    async getCampaigns() {
      let oVue = this;

      if (oVue.isAdmin && oVue.mandanteID === null) return;

      KTUtil.setLoading();

      oVue.campaigns = [];

      const mandante = oVue.isAdmin
        ? oVue.mandanteID
        : oVue.currentUserPersonalInfo.mandanteID;

      const campaigns = await oVue.$store.dispatch(
        GET_CAMPAIGNS_MANDANTE,
        mandante
      );

      oVue.campaigns = campaigns;
      oVue.filters();
      KTUtil.removeLoading();
    },

    getTypes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_CAMPAIGN_TYPES).then((campaignTypes) => {
        oVue.campaignTypes = campaignTypes;
        KTUtil.removeLoading();
      });
    },

    getFieldsTypes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_CAMPAIGN_FIELDS_TYPES).then((fieldsTypes) => {
        oVue.wizardForm.fields.dialog.fieldsTypes = fieldsTypes;
        KTUtil.removeLoading();
      });
    },

    getJobbers() {
      KTUtil.setLoading();
      let oVue = this;
      const estado = 2;
      oVue.$store.dispatch(GET_JOBBERS, estado).then((aJobbers) => {
        if (!oVue.isAdmin)
          aJobbers = aJobbers.filter(
            (aJobber) => aJobber.id_mandante == oVue.mandanteID
          );
        oVue.jobbers = aJobbers;
        KTUtil.removeLoading();
      });
    },

    getEstados() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_ESTADOS_CAMPAIGN).then((estados) => {
        estados.unshift({ estadoID: -1, estadoDescripcion: "Todos" });
        oVue.estados = estados;
        oVue.estado = -1;
        KTUtil.removeLoading();
      });
    },
    getEstadosTareas() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_ESTADOS_TASK).then((estados) => {
        oVue.taskStates = estados;
        KTUtil.removeLoading();
      });
    },
    estadoFilter() {
      let oVue = this;
      if (oVue.estado !== -1) {
        oVue.campaignsFiltered = oVue.campaignsFiltered.filter((t) => {
          return t.estadoID == oVue.estado;
        });
      }
    },
    getStatus() {
      let oVue = this;
      const status = [
        {
          statusID: 1,
          statusNombre: "Activa",
        },
        {
          statusID: 2,
          statusNombre: "Inactiva",
        },
        {
          statusID: -1,
          statusNombre: "Todos",
        },
      ];
      oVue.status = status;
      oVue.statusID = 1;
    },
    statusFilter() {
      let oVue = this;
      const statusID = oVue.statusID;
      if (statusID < 0) return;
      oVue.campaignsFiltered = oVue.campaignsFiltered.filter((t) => {
        const fechaTermino = moment(
          `${t.fechaTermino} 23:59:59`,
          "DD/MM/YYYY HH:mm:ss"
        ).format("YYYY-MM-DD HH:mm:ss");
        if (statusID === 1) return moment().isSameOrBefore(fechaTermino);
        return moment().isAfter(fechaTermino);
      });
    },
    async mandanteFilter() {
      await this.getCampaigns();
      /* let oVue = this;
      if (oVue.mandanteID !== "null") {
        oVue.campaignsFiltered = oVue.campaignsFiltered.filter((t) => {
          return t.mandanteID == oVue.mandanteID;
        });
      } */
    },
    filters() {
      let oVue = this;
      if (oVue.mandanteID !== "null") {
        oVue.campaignsFiltered = oVue.campaigns;
        this.estadoFilter();
        this.getCodigosMandante();
        this.statusFilter();
      }
    },

    async getAllCampaignData(iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllCampaignDataResponse = await oVue.$store.dispatch(
        GET_ALL_CAMPAIGN_DATA,
        { campaign: iCampaignID }
      );
      KTUtil.removeLoading();

      return aAllCampaignDataResponse;
    },

    async newCampaign(aData) {
      let oVue = this;
      const aNewCampaignResponse = await oVue.$store.dispatch(
        NEW_CAMPAIGN_DATA,
        aData
      );
      return aNewCampaignResponse;
    },

    async newFields(aData) {
      let oVue = this;
      const aNewCampaignFieldsResponse = await oVue.$store.dispatch(
        NEW_CAMPAIGN_FIELDS,
        aData
      );
      return aNewCampaignFieldsResponse;
    },

    async updateCampaign(aData) {
      let oVue = this;
      const aUpdateCampaignResponse = await oVue.$store.dispatch(
        UPDATE_CAMPAIGN_DATA,
        aData
      );
      return aUpdateCampaignResponse;
    },

    toggleJobberRequeriments() {
      let oVue = this;
      this.$nextTick(() => {
        if (
          oVue.jobberRequeriments.length ===
          oVue.wizardForm.requeriments.jobberRequeriments.length
        ) {
          oVue.wizardForm.requeriments.jobberRequeriments = [];
        } else {
          oVue.wizardForm.requeriments.jobberRequeriments =
            oVue.jobberRequeriments.slice();
        }
      });
    },

    editCampaign(aCampaign) {
      let oVue = this;

      const iEstadoID = aCampaign.estadoID;
      const iCampaignID = aCampaign.campaignID;
      const iTipoID = aCampaign.tipoID;
      const iCostoTarea = aCampaign.costoTarea;
      const sFechaInicio = aCampaign.fechaInicio;
      const sFechaTermino = aCampaign.fechaTermino;
      const sNombre = aCampaign.nombre;
      const sDescripcion = aCampaign.descripcion;
      const bTieneVolante = Number(aCampaign.tieneVolante) === 1;
      const sNombreCorto = aCampaign.nombreCorto;

      if (iEstadoID === 1) {
        oVue.wizardForm.generalData.campaignID = iCampaignID;
        oVue.wizardForm.generalData.campaignName = sNombre;
        oVue.wizardForm.generalData.campaignDescription = sDescripcion;
        oVue.wizardForm.generalData.campaignType = iTipoID;
        oVue.wizardForm.generalData.campaignWorkCost = iCostoTarea;
        oVue.wizardForm.generalData.campaignDates.push(
          moment(sFechaInicio, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
        oVue.wizardForm.generalData.campaignDates.push(
          moment(sFechaTermino, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
        oVue.wizardForm.generalData.flyer.haveFlyer = bTieneVolante;
        oVue.wizardForm.generalData.flyer.flyerShortCampaignName = sNombreCorto;

        oVue.dialog = true;
        oVue.initializeWizzard();
      }
    },

    deleteCampaign(aCampaign) {
      const iEstadoID = aCampaign.estadoID;

      if (iEstadoID === 1) {
        this.dialogDeleteCampaign = true;
      } else {
        Swal.fire({
          title: "Error!",
          text: "No puede eliminar una campaña aprobada",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },

    async showCampaign(aCampaign) {
      let oVue = this;

      oVue.getCustomCertificates(oVue.mandanteID);

      oVue.isCampaignVisualilzation = true;

      oVue.isCampaignVisualilzation = true;

      const iCampaignID = aCampaign.campaignID;
      const iTipoID = aCampaign.tipoID;
      const sTipo = aCampaign.tipo;
      const iCostoTarea = aCampaign.costoTarea;
      const sFechaInicio = aCampaign.fechaInicio;
      const sFechaTermino = aCampaign.fechaTermino;
      const sNombre = aCampaign.nombre;
      const sDescripcion = aCampaign.descripcion;

      const aAllCampaignDataResponse = await oVue.getAllCampaignData(
        iCampaignID
      );
      if (aAllCampaignDataResponse.result) {
        const aGeneral = aAllCampaignDataResponse.data.general;
        const aFields = aAllCampaignDataResponse.data.fields;
        const aFieldsOptions = aAllCampaignDataResponse.data.options;
        const aJobs = aAllCampaignDataResponse.data.jobs;
        const aSchedules = aAllCampaignDataResponse.data.schedules;

        oVue.campaign = aGeneral;

        oVue.wizardForm.generalData.campaignID = iCampaignID;
        oVue.wizardForm.generalData.campaignName = sNombre;
        oVue.wizardForm.generalData.campaignDescription = sDescripcion;
        oVue.wizardForm.generalData.campaignType = iTipoID;
        oVue.wizardForm.generalData.campaignTypeDescription = sTipo;
        oVue.wizardForm.generalData.campaignWorkCost = iCostoTarea;

        oVue.wizardForm.generalData.campaignDates.push(
          moment(sFechaInicio, "DD-MM-YYYY").format("YYYY-MM-DD")
        );
        oVue.wizardForm.generalData.campaignDates.push(
          moment(sFechaTermino, "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        if (aSchedules.length > 0) {
          oVue.wizardForm.generalData.schedulesDates = _.map(
            aSchedules,
            (aSchedule) => {
              const oMoment = moment(aSchedule.fecha, "YYYY-MM-DD");
              const sFecha = oMoment.format("DD-MM-YYYY");
              const sDia = oMoment.format("dddd");
              return {
                fecha: sFecha,
                dia: sDia,
                horaDesde: aSchedule.horaDesde,
                horaHasta: aSchedule.horaHasta,
                laborable: aSchedule.laborable === 1 ? true : false,
                irrenunciable: aSchedule.irrenunciable === 1 ? true : false,
                orden: aSchedule.orden,
              };
            }
          );
        } else {
          const aDates = [
            moment(sFechaInicio, "DD-MM-YYYY").format("YYYY/MM/DD"),
            moment(sFechaTermino, "DD-MM-YYYY").format("YYYY/MM/DD"),
          ];
          oVue.getScheduleDates(aDates);
          oVue.updateSchedule();
        }

        oVue.wizardForm.fields.finalFields.push(
          {
            titulo: "ID Tarea",
            key: "idJob",
            origen: "MANUAL",
          },
          {
            titulo: "Estado",
            key: "estado",
            origen: "MANUAL",
          },
          {
            titulo: "Fecha de Asignación",
            key: "fechaAsignacion",
            origen: "MANUAL",
          },
          {
            titulo: "Fecha de Realización",
            key: "fechaGestion",
            origen: "MANUAL",
          },
          {
            titulo: "Jobber",
            key: "nombreJobber",
            origen: "MANUAL",
          },
          {
            titulo: "Latitud",
            key: "latitud",
            origen: "MANUAL",
          },
          {
            titulo: "Longitud",
            key: "longitud",
            origen: "MANUAL",
          },
          {
            titulo: "Latitud Jobber",
            key: "latitud_jobber",
            origen: "MANUAL",
          },
          {
            titulo: "Longitud Jobber",
            key: "longitud_jobber",
            origen: "MANUAL",
          },
          {
            titulo: "Distancia",
            key: "distancia_tarea_jobber",
            origen: "MANUAL",
          }
        );

        for (let i = 0; i <= aFields.length - 1; i++) {
          const aField = aFields[i];

          let aFieldOptions = [];

          if (aField.hasOptions) {
            const aOptions = _.filter(aFieldsOptions, {
              idCampo: aField.idCampo,
            });

            aFieldOptions = _.map(aOptions, function (aOption) {
              return {
                value: aOption.opcion,
                enlaceCampo: aOption.enlaceCampo,
                key: aOption.idOpcion,
              };
            });
          }

          let aNewField = {
            titulo: aField.titulo,
            tipo: aField.tipo,
            tipoID: aField.tipoID,
            opciones: aFieldOptions,
            enlaceCampo: aField.enlaceCampo === null ? "" : aField.enlaceCampo,
            key: aField.idCampo,
            origen: aField.origen,
          };
          oVue.wizardForm.fields.finalFields.push(aNewField);
        }

        const aFileFields = _.filter(oVue.wizardForm.fields.finalFields, {
          origen: "FILE",
        });
        oVue.wizardForm.fields.sortedFilesFields = aFileFields;

        const aConfigFields = _.filter(oVue.wizardForm.fields.finalFields, {
          origen: "CONFIG",
        });
        oVue.wizardForm.fields.sortedConfigFields = aConfigFields;

        oVue.wizardForm.fields.headersFileRows = _.map(
          oVue.wizardForm.fields.finalFields,
          function (aField) {
            return {
              text: aField.titulo,
              value:
                aField.origen != "MANUAL"
                  ? "campo_" + aField.key + ".value"
                  : aField.key,
            };
          }
        );

        oVue.wizardForm.fields.headersFileRows.unshift({
          text: "",
          value: "actions",
        });

        oVue.wizardForm.fields.fileRows = _.map(aJobs, function (aJob) {
          let aAttributes =
            typeof aJob.attributes !== "object"
              ? JSON.parse(aJob.attributes)
              : aJob.attributes;
          aAttributes.idJob = aJob.idJob;
          aAttributes.idUsuario = aJob.userID;
          aAttributes.nombreJobber = aJob.user;
          aAttributes.fechaAsignacion = aJob.assignmentDate;
          aAttributes.fechaGestion = aJob.managmentDate;
          aAttributes.asignada = Boolean(aJob.hasAssignment);
          aAttributes.checkAsignar = false;
          aAttributes.latitud = aJob.latitud;
          aAttributes.longitud = aJob.longitud;
          aAttributes.comuna = aJob.comuna;
          aAttributes.idEstado = aJob.statusID;
          aAttributes.estado = aJob.status;
          aAttributes.latitud_jobber = aJob.latitud_jobber;
          aAttributes.longitud_jobber = aJob.longitud_jobber;
          aAttributes.distancia_tarea_jobber = aJob.distancia_tarea_jobber;
          return aAttributes;
        });

        oVue.wizardForm.fields.assignedJobsColumns = _.map(
          _.filter(oVue.wizardForm.fields.finalFields, { origen: "FILE" }),
          function (aField) {
            return {
              text: aField.titulo,
              value: "campo_" + aField.key + ".value",
            };
          }
        );
        oVue.wizardForm.fields.assignedJobsColumns.unshift({
          text: "ID Tarea",
          value: "idJob",
        });

        oVue.wizardForm.fields.assignedJobsColumns.push({
          text: "Jobber",
          value: "nombreJobber",
        });
        oVue.wizardForm.fields.assignedJobsColumns.push({
          text: "Fecha de Asignacion",
          value: "fechaAsignacion",
        });

        oVue.wizardForm.fields.unassignmentJobsColumns.push({
          text: "Asignar",
          value: "asignar",
          sortable: false,
        });
        oVue.wizardForm.fields.unassignmentJobsColumns.push({
          text: "ID Tarea",
          value: "idJob",
        });
        _.map(
          _.filter(oVue.wizardForm.fields.finalFields, { origen: "FILE" }),
          function (aField) {
            oVue.wizardForm.fields.unassignmentJobsColumns.push({
              text: aField.titulo,
              value: "campo_" + aField.key + ".value",
            });
          }
        );

        const aAllTreeDataResponse = await oVue.getCampaignTreeDataExcel(
          iCampaignID
        );
        let iOrdenCampo = 0;
        let iOrdenOpcion = 0;
        oVue.wizardForm.fields.treeRows = _.map(
          aAllTreeDataResponse,
          function (aTree) {
            let obj = new Object();
            Object.keys(aTree).forEach((key) => {
              obj[key] = aTree[key];
            });
            if (aTree.ordenCampo == iOrdenCampo) {
              if (aTree.tieneOpciones == 0) {
                iOrdenOpcion = 1;
              } else {
                iOrdenOpcion++;
              }
              obj["ordenCampo"] = "";
              obj["pregunta"] = "";
              obj["tipoCampo"] = "";
            } else {
              iOrdenCampo = aTree.ordenCampo;
              iOrdenOpcion = 1;
            }
            obj["ordenOpcion"] = iOrdenOpcion;
            return obj;
          }
        );
        oVue.dialogShowCampaign = true;

        oVue.wizardForm.generalData.coberturaZonal = [];
        oVue.wizardForm.generalData.coberturaZonalExcel = [];
        const aComunasJobs = _.chain(oVue.wizardForm.fields.fileRows)
          .groupBy("comuna")
          .map(function (value, index) {
            return { nombre: index, tareas: value };
          })
          .value();
        let bShowCobertura = false;
        if (aComunasJobs.length === 1) {
          if (
            aComunasJobs[0].nombre !== "null" &&
            typeof aComunasJobs[0].nombre !== "undefined"
          ) {
            bShowCobertura = true;
          }
        } else {
          bShowCobertura = true;
        }

        let region, provincia, jobbers;
        if (bShowCobertura) {
          oVue.wizardForm.generalData.coberturaZonal = _.map(
            aComunasJobs,
            (aComunaJob) => {
              const aDataComuna = oVue.regiones.find(
                (region) =>
                  region.comuna
                    .normalize("NFD")
                    .replace(/\p{Diacritic}/gu, "") == aComunaJob.nombre
              );
              if (typeof aDataComuna !== "undefined") {
                region = aDataComuna.region;
                provincia = aDataComuna.provincia;
                if (aDataComuna.jobbers) {
                  jobbers = JSON.parse(aDataComuna.jobbers);
                } else {
                  jobbers = [];
                }
              } else {
                region = provincia = "";
                jobbers = [];
              }
              if (!jobbers[0]) jobbers = [];
              return {
                region,
                provincia,
                nombre: aComunaJob.nombre,
                tareas: aComunaJob.tareas,
                jobbers,
              };
            }
          );
          oVue.wizardForm.generalData.coberturaZonal = _.sortBy(
            oVue.wizardForm.generalData.coberturaZonal,
            "region"
          );

          oVue.wizardForm.generalData.coberturaZonal.forEach(
            (coberturaZonal) => {
              if (coberturaZonal.jobbers.length > 0) {
                coberturaZonal.jobbers.forEach((jobber) => {
                  oVue.wizardForm.generalData.coberturaZonalExcel.push({
                    ...coberturaZonal,
                    jobber: jobber.nombre,
                  });
                });
              } else {
                oVue.wizardForm.generalData.coberturaZonalExcel.push({
                  ...coberturaZonal,
                  jobber: "",
                });
              }
            }
          );
        }

        oVue.getCampaignDailyMessages(iCampaignID);
        if (oVue.$refs.DocumentRepository?.getDocuments) {
          oVue.$refs.DocumentRepository.getDocuments();
        }
      }
    },

    assignJobsToJobber(aData) {
      let oVue = this;

      const iJobberID = Number(aData.field);
      if (iJobberID !== 0) {
        const aJobsToAssign = aData.selectedTasks.map((aJob) => {
          return {
            idJob: aJob.idJob,
            idJobber: iJobberID,
          };
        });
        KTUtil.setLoading();
        this.jobsAssignment(aJobsToAssign).then((response) => {
          KTUtil.removeLoading();
          if (response.result) {
            Swal.fire({
              title: "¡Enhorabuena!",
              text: response.message,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            const aCampaign = oVue.campaign;
            oVue.cleanScreen();
            oVue.showCampaign(aCampaign);
          } else {
            Swal.fire({
              title: "Error!",
              text: response.message,
              icon: "warning",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Debe seleccionar un Jobber",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },
    changeMasiveJobState(aData) {
      let oVue = this;

      const iStateID = Number(aData.field);
      if (iStateID !== 0) {
        const aJobsToChange = aData.selectedTasks.map((aJob) => {
          return {
            idJob: aJob.idJob,
            idState: iStateID,
          };
        });

        KTUtil.setLoading();
        this.tasksStateAssigment(aJobsToChange).then((response) => {
          KTUtil.removeLoading();
          if (response.result) {
            Swal.fire({
              title: "¡Enhorabuena!",
              text: response.message,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            const aCampaign = oVue.campaign;
            oVue.cleanScreen();
            oVue.showCampaign(aCampaign);
          } else {
            Swal.fire({
              title: "Error!",
              text: response.message,
              icon: "warning",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Debe seleccionar un un estado",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },
    async jobsAssignment(aJobs) {
      let oVue = this;
      const aJobsAssignmentResponse = await oVue.$store.dispatch(
        POST_JOBBER_ASSIGNMENT,
        aJobs
      );
      return aJobsAssignmentResponse;
    },

    async tasksStateAssigment(aJobs) {
      let oVue = this;
      const aResultResponse = await oVue.$store.dispatch(
        CHANGE_TASKS_STATE,
        aJobs
      );
      return aResultResponse;
    },

    deleteItemConfirm() {
      this.campaigns.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDeleteCampaign = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.campaigns[this.editedIndex], this.editedItem);
      } else {
        this.campaigns.push(this.editedItem);
      }
      this.close();
    },
    fieldTitle(sKey) {
      let oVue = this;
      if (sKey !== null) {
        const aField = _.findWhere(oVue.wizardForm.fields.finalFields, {
          key: sKey,
        });
        return aField.titulo;
      } else {
        return "";
      }
    },
    fieldType(sKey) {
      let oVue = this;
      const aField = _.findWhere(oVue.wizardForm.fields.finalFields, {
        key: sKey,
      });
      return aField.tipo;
    },
    fieldHasOptions(sKey) {
      let oVue = this;

      const aField = _.findWhere(oVue.wizardForm.fields.finalFields, {
        key: sKey,
      });

      const iFieldTypeID = aField.tipoID;

      const aFieldType = _.findWhere(
        oVue.wizardForm.fields.dialog.fieldsTypes,
        { id: iFieldTypeID }
      );
      return Boolean(aFieldType.opciones);
    },
    downloadExcelFile(filename, id, cols) {
      let oVue = this;
      const wb = (XLSX.WorkBook = XLSX.utils.book_new());

      //Datos de la campaña
      const infoXLSXCampaign = getInfoXLSXCampaign(oVue.campaign);
      const ws_campaign_name = "campaña";
      const ws_campaign = XLSX.utils.aoa_to_sheet(infoXLSXCampaign);
      wb.SheetNames.push(ws_campaign_name);
      wb.Sheets[ws_campaign_name] = ws_campaign;

      //Datos personalizados
      const elt = KTUtil.getById(id);
      const table = elt.querySelector("table");
      const ws = (XLSX.WorkSheet = XLSX.utils.table_to_sheet(table, {
        raw: true,
      }));

      let row = 0;
      for (let col = 0; col <= cols; col++) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col });
        if (ws[ref]) {
          ws[ref].s = {
            font: {
              bold: true,
            },
          };
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, filename);

      return XLSX.writeFile(wb, `${filename}.xlsx`);
    },
    async getCampaignTreeDataExcel(iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllCampaignTreeDataExcelResponse = await oVue.$store.dispatch(
        GET_CAMPAIGN_TREE_DATA_EXCEL,
        { campaign: iCampaignID }
      );
      KTUtil.removeLoading();

      return aAllCampaignTreeDataExcelResponse;
    },
    rowSchedulesDatesClass(item) {
      let sBgClass = "";
      if (item.irrenunciable) {
        sBgClass = "bg-danger";
      } else {
        if (item.laborable === false) {
          sBgClass = "bg-warning";
        }
      }
      return sBgClass;
    },

    getInalienableCalendar() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_INALIENABLE_CALENDAR).then((aResponse) => {
        oVue.inalienableCalendar = aResponse;
        KTUtil.removeLoading();
      });
    },

    updateSchedule() {
      let oVue = this;
      const aData = {
        campaign: oVue.wizardForm.generalData.campaignID,
        schedule: oVue.wizardForm.generalData.schedulesDates,
      };
      oVue.updateCampaignSchedule(aData).then((aResponse) => {
        KTUtil.removeLoading();
        if (aResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: "El calendario fue actualizado correctamente",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
        } else {
          Swal.fire({
            title: "Error al actualizar el calendario",
            text: aResponse.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      });
    },

    async updateCampaignSchedule(aData) {
      let oVue = this;
      const aUpdateCampaignScheduleResponse = await oVue.$store.dispatch(
        UPDATE_CAMPAIGN_SCHEDULE,
        aData
      );
      return aUpdateCampaignScheduleResponse;
    },

    getScheduleDates(aDates) {
      let oVue = this;

      let sStartDate = moment(aDates[0], "YYYY/MM/DD");
      let sEndDate = moment(aDates[1], "YYYY/MM/DD");
      let diff = sEndDate.diff(sStartDate, "days");
      for (let i = 0; i <= diff; i++) {
        const oMoment = moment(sStartDate).add(i, "days");
        const sFecha = oMoment.format("DD-MM-YYYY");
        const sDia = oMoment.format("dddd");
        const bIrrenunciable =
          typeof _.find(oVue.inalienableCalendar, { fecha: sFecha }) !==
          "undefined"
            ? true
            : false;
        let bLaborable = bIrrenunciable ? false : true;
        const horaDesde = bLaborable ? "09:00:00" : "00:00:00";
        const horaHasta = bLaborable ? "19:00:00" : "00:00:00";

        bLaborable = sDia === "domingo" ? false : bLaborable;

        this.wizardForm.generalData.schedulesDates.push({
          fecha: sFecha,
          dia: sDia,
          horaDesde: horaDesde,
          horaHasta: horaHasta,
          laborable: bLaborable,
          irrenunciable: bIrrenunciable,
          orden: i + 1,
        });
      }
    },

    unassignJob(aJob) {
      let oVue = this;
      const iJobID = aJob.idJob;
      const iJobberID = aJob.idUsuario;
      oVue.unassignJobRequest(iJobID, iJobberID).then((aResponse) => {
        if (aResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: "La tarea fue desasignada correctamente",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
          KTUtil.setLoading();
          oVue.wizardForm.fields.fileRows.forEach((aJob, iIndex) => {
            const iJobIDTmp = aJob.idJob;
            if (iJobID === iJobIDTmp) {
              oVue.wizardForm.fields.fileRows[iIndex].asignada = false;
            }
          });
          KTUtil.removeLoading();
        } else {
          Swal.fire({
            title: "Error al desasignar la tarea",
            text: aResponse.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      });
    },

    async unassignJobRequest(iJobID, iJobberID) {
      KTUtil.setLoading();
      let oVue = this;
      const aUnassignJobResponse = await oVue.$store.dispatch(
        UNASSIGN_JOB_JOBBER,
        { job: iJobID, jobber: iJobberID }
      );
      KTUtil.removeLoading();

      return aUnassignJobResponse;
    },

    getRegiones() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_REGIONES).then((aResponse) => {
        oVue.regiones = aResponse;
        KTUtil.removeLoading();
      });
    },
    getCodigosMandante() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_CODIGOS, oVue.mandanteID).then((aResponse) => {
        oVue.aCodigos = aResponse;
        KTUtil.removeLoading();
      });
    },

    openMassiveJobberMessagesModal() {
      let oVue = this;
      oVue.wizardForm.dailyMessages.dialogMassiveJobberMessage = true;

      let aJobbers = [];
      const aJobbersTmp = _.sortBy(
        _.chain(oVue.wizardForm.fields.fileRows)
          .groupBy("nombreJobber")
          .map(function (value, index) {
            return {
              nombre: index,
              idJobber: value.length > 0 ? value[0].idUsuario : null,
              jobs: value,
            };
          })
          .value(),
        "nombre"
      );
      for (let i = 0; i <= aJobbersTmp.length - 1; i++) {
        let aJobber = aJobbersTmp[i];
        if (aJobber.nombre !== "null") {
          aJobber.selected = false;
          aJobbers.push(aJobber);
        }
      }

      oVue.wizardForm.dailyMessages.campaignJobbersAssigned = aJobbers;
    },

    openGroupJobberMessageModal() {
      let oVue = this;

      const aJobbers = _.filter(
        oVue.wizardForm.dailyMessages.campaignJobbersAssigned,
        { selected: true }
      );
      if (aJobbers.length > 0) {
        oVue.wizardForm.dailyMessages.dialogJobberMessage = true;
      } else {
        Swal.fire({
          title: "¡Alerta!",
          text: "Debe seleccionar almenos 1 jobber",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },

    async sendGroupJobberMessage() {
      let oVue = this;

      if (oVue.wizardForm.dailyMessages.form.groupJobberMessage !== "") {
        const aJobbers = _.map(
          _.filter(oVue.wizardForm.dailyMessages.campaignJobbersAssigned, {
            selected: true,
          }),
          function (aJobber) {
            return {
              id: aJobber.idJobber,
            };
          }
        );

        const aData = {
          aJobbers: aJobbers,
          iCreatorID: oVue.currentUserPersonalInfo.usuarioID,
          sMensaje: oVue.wizardForm.dailyMessages.form.groupJobberMessage,
          sFecha: oVue.wizardForm.dailyMessages.form.sFechaMensaje,
          iCampaignID: oVue.wizardForm.generalData.campaignID,
        };

        const sendGroupMessagesResponse = await oVue.sendGroupMessages(aData);
        if (sendGroupMessagesResponse.result) {
          oVue.wizardForm.dailyMessages.form.groupJobberMessage = "";
          oVue.wizardForm.dailyMessages.dialogJobberMessage = false;
          oVue.wizardForm.dailyMessages.campaignJobbersAssigned = [];
          oVue.wizardForm.dailyMessages.dialogMassiveJobberMessage = false;

          Swal.fire({
            title: "¡Enhorabuena!",
            text: "Los mensajes fueron enviados",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });

          oVue.getCampaignDailyMessages(oVue.wizardForm.generalData.campaignID);
        } else {
          Swal.fire({
            title: "¡Error!",
            text: sendGroupMessagesResponse.message,
            icon: "warning",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      } else {
        Swal.fire({
          title: "¡Alerta!",
          text: "Debe ingresar un mensaje",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },

    async sendGroupMessages(aData) {
      let oVue = this;

      KTUtil.setLoading();

      const aResponse = await oVue.$store.dispatch(
        MASSIVE_JOBBER_MESSAGE,
        aData
      );

      KTUtil.removeLoading();
      return aResponse;
    },

    getCampaignDailyMessages(iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_CAMPAIGN_DAILY_MESSAGES_BY_JOBBER_ID, iCampaignID)
        .then((aDailyMessages) => {
          oVue.wizardForm.dailyMessages.aCampaignDailyMessages = aDailyMessages;
          KTUtil.removeLoading();
        });
    },
    changeStateAllMessages() {
      let oVue = this;
      oVue.wizardForm.dailyMessages.campaignJobbersAssigned =
        oVue.wizardForm.dailyMessages.campaignJobbersAssigned.map(
          //eslint-disable-next-line
          ({ selected, ...rest }) => ({
            ...rest,
            selected: this.selectedAllMessages,
          })
        );
    },
    async getCustomCertificates(iMandanteID) {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_CUSTOM_CERTFIEDS, { mandante: iMandanteID })
        .then((aCertificates) => {
          oVue.wizardForm.fields.customCertificate.certifieds = aCertificates;
        });
      KTUtil.removeLoading();
    },
    selectCustomCertificate() {
      let oVue = this;
      const iSelectedCertificate =
        oVue.wizardForm.fields.customCertificate.selectedCertificate;
      const aCertificates = oVue.wizardForm.fields.customCertificate.certifieds;
      let aCertificate = aCertificates.filter((aCertificate) => {
        return aCertificate.uniqueID === iSelectedCertificate;
      });
      if (aCertificate.length > 0) {
        [aCertificate] = aCertificate;
        oVue.wizardForm.fields.customCertificate.configs =
          aCertificate.configuraciones;
      }
    },
    selectAllUnassigmentTask() {
      let oVue = this;

      const bCheckValue = oVue.wizardForm.fields.selectedAllUnassigmentTask;

      for (let i in oVue.wizardForm.fields.filteredUnassigmentJobbers) {
        oVue.wizardForm.fields.filteredUnassigmentJobbers[i].checkAsignar =
          bCheckValue ? true : false;
      }
    },
    async sendCustomCertifiedData() {
      const oVue = this;

      KTUtil.setLoading();

      let aData = {
        campaign: oVue.wizardForm.generalData.campaignID,
        certificate:
          oVue.wizardForm.fields.customCertificate.selectedCertificate,
        config: oVue.wizardForm.fields.customCertificate.selectedConfig,
      };

      await oVue.configCustomCertified(aData);

      KTUtil.removeLoading();
    },

    async configCustomCertified(aData) {
      let oVue = this;
      const aRequestResponse = await oVue.$store.dispatch(
        CONFIG_CUSTOM_CERTFIED,
        aData
      );
      return aRequestResponse;
    },
    getTasksFiltered(aTasks) {
      let oVue = this;
      let params = { campaign: oVue.campaign.campaignID };
      const aTasksID = aTasks.map((t) => t.idJob);
      params.tasks = aTasksID.join(",");

      oVue.$refs.TaskListDialog.getTasks(params);
    },
    getJobbersFiltered(aJobbers) {
      let oVue = this;
      let params = {};
      if (Array.isArray(aJobbers[0])) {
        let aJobbersTmp = [];
        aJobbers.forEach((aJobber) => {
          if (Object.keys(aJobber).length > 0) aJobbersTmp.push(...aJobber);
        });
        aJobbers = aJobbersTmp;
      }
      const aJobbersID = aJobbers.map((t) => t.id);
      params.jobbers = aJobbersID.join(",");

      oVue.$refs.JobberListDialog.getJobbers(params);
    },
    getFromFileRows(key) {
      const oVue = this;
      return oVue.wizardForm.fields.fileRows.length > 0 &&
        key !== "" &&
        key !== null
        ? _.findWhere(oVue.wizardForm.fields.fileColumns, {
            key: key,
          })?.name
        : "";
    },
    updateEndDateReset() {
      this.updateEndDateValues.date = null;
      this.updateEndDateValues.dateOriginal = null;
      this.updateEndDateValues.campaignId = null;
    },
    updateEndDateDialog({ campaignID, fechaTermino }) {
      this.updateEndDateReset();
      this.updateEndDateValues.campaignId = campaignID;
      this.updateEndDateValues.dateOriginal = moment(
        fechaTermino,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      this.updateEndDateValues.dialog = true;
    },
    closeUpdateEndDateDialog() {
      this.updateEndDateReset();
      this.updateEndDateValues.dialog = false;
    },
    async updateEndDate() {
      if (this.endDateSameOrBefore) {
        Swal.fire({
          title: "¡Alerta!",
          text: "Debe seleccionar una fecha mayor a la del dia",
          icon: "warning",
        });
        return;
      }

      if (this.monthsBetweenEndDate > 0) {
        Swal.fire({
          title: "¡Alerta!",
          text: "La nueva fecha no puede prolongarse mas de 1 mes",
          icon: "warning",
        });
        return;
      }

      const payload = {
        campaignId: this.updateEndDateValues.campaignId,
        date: this.updateEndDateValues.dateOriginal,
      };

      try {
        await this.$store.dispatch(UPDATE_END_DATE, payload);

        this.closeUpdateEndDateDialog();

        Swal.fire({
          title: "Enhorabuena!",
          text: "La fecha de termino ha sido cambiada",
          icon: "success",
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getWrongComunes() {
      KTUtil.setLoading();

      const wrongComunes = await this.$store.dispatch(GET_WRONG_COMUNES);
      this.comunes.wrongs = wrongComunes;
      KTUtil.removeLoading();
    },
    async getComunes() {
      KTUtil.setLoading();

      const comunes = await this.$store.dispatch(
        GET_REGIONES_PROVINCIAS_COMUNAS
      );
      this.comunes.rights = comunes;

      KTUtil.removeLoading();
    },
    async validateComunes() {
      const rows = this.wizardForm.fields.fileRows;
      const comuneField = this.getFromFileRows(this.wizardForm.fields.comuna);

      for (const [index, row] of rows.entries()) {
        const comuneRow = row?.[comuneField];

        const comune = this.comunes.rights.find(
          ({ comuna }) => comuna.toLowerCase() === comuneRow.toLowerCase()
        );

        if (comune) continue;

        const wrongComune = this.comuneExistsInWrongComunes(comuneRow);

        if (!wrongComune) {
          /**
           * Introducir codigo para validar comunas no registradas como erroneas y que no existen en el maestro de comunas
           */
          continue;
        }

        this.wizardForm.fields.fileRows[index][comuneField] =
          wrongComune.comuna;
      }
    },
    comuneExistsInWrongComunes(value) {
      const wrongComune = this.comunes.wrongs.find(
        (comune) => comune.name.toLowerCase() === value.toLowerCase()
      );

      if (!wrongComune) return;

      return this.comunes.rights.find(
        (comune) => comune.id_comuna === wrongComune.comuneId
      );
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    dateRangeText() {
      let oVue = this;
      const aDates = oVue.wizardForm.generalData.campaignDates;

      let sFechaIni = "";
      let sFechaFin = "";
      if (typeof aDates[0] !== "undefined") {
        sFechaIni = moment(aDates[0], "YYYY/MM/DD").format("DD-MM-YYYY");
      }
      if (typeof aDates[1] !== "undefined") {
        sFechaFin = moment(aDates[1], "YYYY/MM/DD").format("DD-MM-YYYY");
      }

      if (oVue.isCampaignVisualilzation) {
        return sFechaIni + " ~ " + sFechaFin;
      } else {
        oVue.wizardForm.generalData.schedulesDates = [];

        if (aDates.length === 2) {
          oVue.getScheduleDates(aDates);
        }

        return sFechaIni + " ~ " + sFechaFin;
      }
    },
    showRequiredFieldCheck() {
      let oVue = this;

      let bToReturn = false;

      if (oVue.wizardForm.fields.dialog.type !== "") {
        const iFieldID = Number(oVue.wizardForm.fields.dialog.type);

        switch (iFieldID) {
          case 3:
          case 8:
            // No mostrar el check debido a que por obligacion estos campos tienen que llenarse siempre.
            break;
          default:
            bToReturn = true;
        }
      }

      return bToReturn;
    },
    endDateSameOrBefore() {
      return moment(this.updateEndDateValues.dateOriginal).isSameOrBefore(
        moment()
      );
    },
    monthsBetweenEndDate() {
      return moment(this.updateEndDateValues.dateOriginal).diff(
        moment(),
        "months"
      );
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDeleteCampaign(val) {
      val || this.closeDelete();
    },
    "wizardForm.fields.dialog.type"(val) {
      let oVue = this;
      if (val !== "" && val !== null) {
        const aFieldType = _.findWhere(
          oVue.wizardForm.fields.dialog.fieldsTypes,
          { id: val }
        );
        oVue.wizardForm.fields.dialog.hasOptions = aFieldType.opciones;
      } else {
        oVue.wizardForm.fields.dialog.hasOptions = false;
      }
    },
    "wizardForm.dailyMessages.form.sFechaMensajeDatepicker"(val) {
      let oVue = this;
      if (val !== "" && val !== null) {
        oVue.wizardForm.dailyMessages.form.sFechaMensaje = moment(
          val,
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY");
      }
    },
    "updateEndDateValues.dateOriginal"() {
      this.updateEndDateValues.date = moment(
        this.updateEndDateValues.dateOriginal,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
    },
  },
};
</script>
<style scoped lang="scss">
.columnsContainer {
  height: 400px;
  overflow-y: auto;
}
::v-deep .v-application--wrap {
  min-height: fit-content;
}
</style>
